import React, { useState } from "react";
import Footer from "../widgets/Footer";
import Navbar from "../widgets/Navbar";
// import Sidebar from "../widgets/Sidebar";
import { ForgotValid } from "../validations/ForgotValid";
import { useChangePasswordMutation } from "../../redux/userApi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const ChangePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordErr, setOldPasswordErr] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [btnnDisable, setBtnnDisable] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [type, setType] = useState("password");
  const [newType, setNewType] = useState("password");
  const [confirmType, setConfirmType] = useState("password");
  const [passwordShow, setPasswordShow] = useState({
    eye: "bi-eye-slash",
    type: "password",
  });
  const [passwordFiledErr, setPasswordFiledErr] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [resetPassword] = useChangePasswordMutation();

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    if (name === "oldPassword") {
      if (!value) {
        setOldPasswordErr("This field is required");
      } else {
        setOldPasswordErr("");
      }
      setOldPassword(value);
    }

    if (name === "newPassword") {
      if (!value) {
        setPasswordFiledErr({
          ...passwordFiledErr,
          newPassword: "This field is required",
        });
      }
      setNewPassword(value);
    }
    if (name === "confirmPassword") {
      if (!value) {
        setPasswordFiledErr({
          ...passwordFiledErr,
          confirmPassword: "This field is required",
        });
      } else {
        setPasswordFiledErr({
          ...passwordFiledErr,
          confirmPassword: "",
        });
      }
      setConfirmPassword(value);
    }
    const checkRegister = ForgotValid(name, value);
    console.log("checkRegister", checkRegister);
    setPasswordFiledErr({ ...passwordFiledErr, [name]: checkRegister });

    if (name === "confirmPassword") {
      if (value !== newPassword) {
        setPasswordFiledErr({
          ...passwordFiledErr,
          confirmPassword: "Password does not match",
        });
      } else {
        setPasswordFiledErr({
          ...passwordFiledErr,
          confirmPassword: "",
        });
      }
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();
    if (!newPassword && !confirmPassword && !oldPassword) {
      setPasswordFiledErr({
        ...passwordFiledErr,
        newPassword: "This field is required",
        confirmPassword: "This field is required",
      });
      setOldPasswordErr("This field is required");
      return false;
    } else {
      setPasswordFiledErr({
        ...passwordFiledErr,
        newPassword: "",
        confirmPassword: "",
      });
    }

    if (newPassword !== confirmPassword) {
      setPasswordFiledErr({
        ...passwordFiledErr,
        confirmPassword: "Password does not match",
      });
      return;
    }

    const ForgotData = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    const changePass = await resetPassword(ForgotData);
    const resp = changePass.data;
    console.log("changePass", resp);
    if (resp.status === false) {
      toast.error(t(resp.message));
    } else {
      toast.success(t(resp.message));
      localStorage.clear();
      setTimeout(() => {
        window.location.href = "/login";
      }, 3000);
    }
  };

  const showcurrentPassword = () => {
    if (type === "password") {
      setPasswordShow({ eye: "bi-eye", type: "text" });
      setType("text");
    } else {
      setPasswordShow({ eye: "bi-eye-slash", type: "password" });
      setType("password");
    }
  };

  const showNewPassword = () => {
    if (newType === "password") {
      setNewType("text");
    } else {
      setNewType("password");
    }
  };

  const showConfirmPassword = () => {
    if (confirmType === "password") {
      setConfirmType("text");
    } else {
      setConfirmType("password");
    }
  };

  return (
    <div>
      <Navbar />
      <section className="  border_all br_0 bl_0  min_h70vh">
        <div className="container "> 
        <div className="row "> <div className="col-12 p-0">
          <div className="p-3 p-md-4   mb-4  "> 
          <div className="row ">
            {/* <Sidebar /> */}
            <div className="col-xl-4  ">
             
                <h5 className="mb-3">{t("Change Password")}</h5>
                <form>
                  <div>
                    <div className="form-group">
                      <label className="fw-500">{t("Old Password")}</label>
                      <div className="eye_pass position-relative">
                        <input
                          className="form-control"
                          name="oldPassword"
                          id="oldPassword"
                          placeholder={t("Enter Old Password")}
                          onChange={handleChangeInput}
                          value={oldPassword}
                          type={type}
                        />
                        <span
                          className="password__show position-absolute eye1"
                          onClick={showcurrentPassword}
                        >
                          <i className={`bi ${passwordShow.eye}`}></i>
                        </span>
                      </div>
                      <span style={{ color: "red" }}>{t(oldPasswordErr)}</span>
                    </div>
                    <div className="form-group">
                      <label className="fw-500">{t("New Password")}</label>
                      <div className="eye_pass position-relative">
                        <input
                          className="form-control"
                          name="newPassword"
                          id="newPassword"
                          placeholder={t("Enter New Password")}
                          onChange={handleChangeInput}
                          value={newPassword}
                          type={newType}
                        />
                        <span
                          className="password__show position-absolute eye1"
                          onClick={showNewPassword}
                        >
                          {newType === "password" ? (
                            <i className="bi bi-eye-slash"></i>
                          ) : (
                            <i className="bi bi-eye"></i>
                          )}
                        </span>
                      </div>
                      <span style={{ color: "red" }}>
                        {t(passwordFiledErr.newPassword)}
                      </span>
                    </div>
                    <div className="form-group">
                      <label className="fw-500">{t("Confirm New Password")}</label>
                      <div className="eye_pass position-relative">
                        <input
                          className="form-control"
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder={t("Enter Confirm Password")}
                          onChange={handleChangeInput}
                          value={confirmPassword}
                          type={confirmType}
                        />
                        <span
                          className="password__show position-absolute eye1"
                          onClick={showConfirmPassword}
                        >
                          {confirmType === "password" ? (
                            <i className="bi bi-eye-slash"></i>
                          ) : (
                            <i className="bi bi-eye"></i>
                          )}
                        </span>
                      </div>
                      <span style={{ color: "red" }}>
                        {t(passwordFiledErr.confirmPassword)}
                      </span>
                    </div>
                    <div className="form-group">
                      <button
                        onClick={changePassword}
                        className="btn w100px btn_man "
                        disabled={btnnDisable}
                      >
                        {t("Change Password")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ChangePassword;
