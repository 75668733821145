import React, { useState } from "react";
import Navbar from "../widgets/Navbar";
// import Sidebar from "../widgets/Sidebar";
import { useTranslation } from "react-i18next";
import {
  useAccountDetailsMutation,
  useGetAccountDetailsQuery,
  usePersonalDetailsMutation,
} from "../../redux/accountApi";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";

const Profile = () => {
  const { t } = useTranslation();
  const [formError, setFormError] = useState({
    bank: {},
    crypto: {},
    email: {},
    mobile: {},
  });
  const [formData, setFormData] = useState({
    firstName: "",
    surname: "",
    accountNumber: "",
    bankName: "",
    cryptoWallet: "",
    email: "",
    mobile: "",
  });
  const [automateCountryCode, setAutomateCountryCode] = useState("us");
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [mobileDisabled, setMobileDisabled] = useState(false);
  const [isInformation, setIsInformation] = useState(false);
  const {
    data: userDetails,
    isLoading,
    error,
    refetch,
  } = useGetAccountDetailsQuery();
  const [addAccountDetails, { isLoading: isSubmitting }] =
    useAccountDetailsMutation();
  const [addPersonalDetails, { isLoading: isSubmittingPersonal }] =
    usePersonalDetailsMutation();

  // Load existing data into formData state
  React.useEffect(() => {
    if (userDetails) {
      setFormData({
        firstName: userDetails.first_name || "",
        surname: userDetails.surname || "",
        accountNumber: userDetails.account_number || "",
        bankName: userDetails.bank_name || "",
        cryptoWallet: userDetails.crypto_wallet || "",
        email: userDetails.email || "",
        mobile: userDetails.mobile_number || "",
      });

      if (userDetails.email && userDetails.mobile_number) {
        setEmailDisabled(true);
        setMobileDisabled(true);
        setIsInformation(true);
      } else {
        if (userDetails.email) {
          setEmailDisabled(true);
        }
        if (userDetails.mobile_number) {
          setMobileDisabled(true);
        }
      }
    }
  }, [userDetails]);

  const validateField = (name, value, formType) => {
    if (!value.trim()) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        [formType]: {
          ...prevErrors[formType],
          [name]: t("This field is required"),
        },
      }));
    } else {
      setFormError((prevErrors) => ({
        ...prevErrors,
        [formType]: {
          ...prevErrors[formType],
          [name]: "",
        },
      }));
    }
  };

  const handleInputChange = (e, formType) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validateField(name, value, formType);
  };

  const handleSubmit = async (event, formType) => {
    event.preventDefault();
    const isValid =
      formType === "bank"
        ? formData.firstName &&
          formData.surname &&
          formData.accountNumber &&
          formData.bankName
        : formData.cryptoWallet;

    if (!isValid) {
      toast.error(t("Please fill out all required fields."));
      return;
    }

    const payload =
      formType === "bank"
        ? {
            type: "bank",
            firstName: formData.firstName,
            surname: formData.surname,
            accountNumber: formData.accountNumber,
            bankName: formData.bankName,
          }
        : {
            type: "crypto",
            cryptoWallet: formData.cryptoWallet,
          };

    try {
      const response = await addAccountDetails(payload).unwrap();
      toast.success(t("Details submitted successfully!"));
    } catch (error) {
      toast.error(t("An error occurred. Please try again."));
    }
  };

  const handleSubmitProfile = async (event) => {
    event.preventDefault();
    const isValid = formData.email && formData.mobile;

    if (!isValid) {
      toast.error(t("Please fill out all required fields."));
      return;
    }

    // Determine the update type
    const updateType =
      formData.email !== userDetails.email
        ? "email"
        : formData.mobile !== userDetails.mobile_number
        ? "mobile"
        : null;

    const payload = {
      email: formData.email,
      mobile_number: formData.mobile,
      updateType,
    };
    console.log(payload);

    try {
      const response = await addPersonalDetails(payload).unwrap();
      if (response?.status) {
        toast.success(t("Details submitted successfully!"));
      } else {
        toast.error(t(response.message));
      }
      refetch();
    } catch (error) {
      toast.error(t("An error occurred. Please try again."));
    }
  };

  const handleInputChangeMobile = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      mobile: value,
    }));
  };

  return (
    <div>
      <Navbar />
      <section className="  border_all br_0 bl_0  min_h70vh">
        <div className="container">
          <div className="row">
            {/* <Sidebar /> */}
            <div className="col-xl-12  ">
              {isLoading && <p>{t("Loading...")}</p>}
              {error && (
                <p className="text-danger">
                  {t("Failed to load user details")}
                </p>
              )}

              <div className="p-3 p-md-4">
                <h5 className="mb-2">{t("Personal Information")}</h5>
                <form onSubmit={(e) => handleSubmitProfile(e)} noValidate>
                  <input type="hidden" name="type" value="bank" />
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="email">{t("Email Address")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={(e) => handleInputChange(e, "email")}
                        placeholder={t("Enter email")}
                        required
                        disabled={emailDisabled}
                      />
                      {formError.email.email && (
                        <p className="text-danger mt-1">
                          {formError.email.email}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6  mb-3">
                      <label htmlFor="surname">{t("Mobile Number")}</label>
                      <PhoneInput
                        key={"phoneInput"}
                        country={automateCountryCode}
                        value={String(formData.mobile)}
                        onChange={(e) => handleInputChangeMobile(e, "mobile")}
                        className=""
                        placeholder="Enter mobile number"
                        countryCodeEditable={false}
                        enableSearch={true}
                        inputProps={{
                          autoFocus: true,
                          name: "mobile",
                        }}
                        disabled={mobileDisabled}
                      />

                      {formError.mobile.mobile && (
                        <p className="text-danger mt-1">
                          {formError.mobile.mobile}
                        </p>
                      )}
                    </div>
                  </div>
                  {isInformation ? (
                    <></>
                  ) : (
                    <div className="mt-4">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmittingPersonal}
                      >
                        {isSubmittingPersonal
                          ? t("Submitting...")
                          : t("Submit Personal Details")}
                      </button>
                    </div>
                  )}
                </form>
              </div>

              <div className="p-3 bg2 br20 border_all br_0 bl_0 pb-md-4">
                <h5 className="mb-2">{t("Banking Details")}</h5>
                <form onSubmit={(e) => handleSubmit(e, "bank")} noValidate>
                  <input type="hidden" name="type" value="bank" />
                  <div className="row">
                    <div className="col-md-6  mb-3">
                      <label htmlFor="firstName">{t("First Name")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={(e) => handleInputChange(e, "bank")}
                        placeholder={t("Enter first name")}
                        required
                      />
                      {formError.bank.firstName && (
                        <p className="text-danger mt-1">
                          {formError.bank.firstName}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6  mb-3">
                      <label htmlFor="surname">{t("Lastname")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="surname"
                        name="surname"
                        value={formData.surname}
                        onChange={(e) => handleInputChange(e, "bank")}
                        placeholder={t("Enter Lastname")}
                        required
                      />
                      {formError.bank.surname && (
                        <p className="text-danger mt-1">
                          {formError.bank.surname}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6  mb-3">
                      <label htmlFor="accountNumber">
                        {t("Account Number")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="accountNumber"
                        name="accountNumber"
                        value={formData.accountNumber}
                        onChange={(e) => handleInputChange(e, "bank")}
                        placeholder={t("Enter account number")}
                        required
                      />
                      {formError.bank.accountNumber && (
                        <p className="text-danger mt-1">
                          {formError.bank.accountNumber}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6  mb-3">
                      <label htmlFor="bankName">{t("Bank Name")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="bankName"
                        name="bankName"
                        value={formData.bankName}
                        onChange={(e) => handleInputChange(e, "bank")}
                        placeholder={t("Enter bank name")}
                        required
                      />
                      {formError.bank.bankName && (
                        <p className="text-danger mt-1">
                          {formError.bank.bankName}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? t("Submitting...")
                        : t("Submit Banking Details")}
                    </button>
                  </div>
                </form>
              </div>

              <div className="p-3 bg2 br20  pb-md-4">
                <h5 className="mb-2">{t("Crypto Wallet Address")}</h5>
                <form onSubmit={(e) => handleSubmit(e, "crypto")} noValidate>
                  <input type="hidden" name="type" value="crypto" />
                  <div className="row">
                    <div className="col-md-6  mb-3">
                      <label htmlFor="cryptoWallet">
                        {t("Wallet Address")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="cryptoWallet"
                        name="cryptoWallet"
                        value={formData.cryptoWallet}
                        onChange={(e) => handleInputChange(e, "crypto")}
                        placeholder={t("Enter crypto wallet address")}
                        required
                      />
                      {formError.crypto.cryptoWallet && (
                        <p className="text-danger mt-1">
                          {formError.crypto.cryptoWallet}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? t("Submitting...")
                        : t("Submit Wallet Address")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profile;
