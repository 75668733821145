import React, { useEffect, useState } from "react";
import { imageUrl } from "../constant/BaseUrl";
import toast from "react-hot-toast";
import Moment from "moment";
// import { ImageValidation } from "./Validation/ImageValidation";
import { useParams } from "react-router-dom";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import { useGetTickitChatByIdMutation } from "../../redux/supportApi";
import { addChats, getSingleTIckitInfo } from "../services/Support";
import { useTranslation } from "react-i18next";

const ChattingFunction = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const [getTickitChatById, { data: record }] = useGetTickitChatByIdMutation();

  const verifyidpic = "/img/support.jpg";
  const tichekId = params.ticketId;

  const [tabledata1, setTabledata1] = useState([]);
  const [ResponseData, setResponseData] = useState("");
  const [issueImageSecond, setIssueImageSecond] = useState("");
  const [subject, setSubject] = useState("");
  const [OpenSteps, setOpenSteps] = useState("Send_Message");
  const [OpenStepsAll, setOpenStepsAll] = useState("all");
  const [dateTime, setDateTime] = useState("");
  const [tabledata, setTabledata] = useState([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [query, setQuery] = useState("");
  const [queryIssue, setQueryIssue] = useState("");
  const [userUploadImage, setUserUploadImage] = useState("/img/support.jpg");
  const [imageError, setImageError] = useState("");
  const [issueText, setIssueText] = useState("");
  const [queryImage, setQueryImage] = useState(false);
  const [image, setImage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const decodes = atob(tichekId);
      const userIdData = { tichekId: decodes };

      try {
        await getTickitChatById(userIdData);
      } catch (error) {
        console.error("Error fetching tickit chat:", error);
      }
    };

    fetchData();
    TicketList();
  }, [tichekId, getTickitChatById]);

  useEffect(() => {
    if (record && record.length > 0) {
      console.log("Records:", record);
      // Perform any logic based on the 'record' data
      setTabledata(record);
    }
  }, [record]);

  const ticketchat = async () => {
    const decodes = atob(tichekId);
    const userIdData = { tichekId: decodes };
    await getTickitChatById(userIdData);
    console.log("ercords", record);
    if (record) {
    }
  };

  const chatSubmit = async (e) => {
    console.log("working", query);
    if (!query) {
      setQueryIssue("Please enter your message");
      return false;
    }

    let hasLeadingSpace = /^\s/.test(query);
    // let hasTrailingSpace = /\s$/.test(query);
    console.log(
      "hasTrailingSpace",
      // hasTrailingSpace,
      "hasLeadingSpace",
      hasLeadingSpace
    );

    if (hasLeadingSpace) {
      setQueryIssue("Please enter a valid message");
      return false;
    }

    // if (hasTrailingSpace) {
    //   setQueryIssue("Please enter a valid message");
    //   return false;
    // }

    const decodes = atob(tichekId);

    const formData = new FormData();
    formData.append("tichekId", decodes);
    formData.append("query", query);
    formData.append("queryImage", queryImage);
    formData.append("replyBy", 2);

    setBtnDisable(false);

    const resp = await addChats(formData);
    if (resp.status === true) {
      ticketchat();
      setQuery("");
      setQueryIssue("");
      setQueryImage(false);
      setUserUploadImage(false);

      toast.success(t(resp.message));
      return false;
    } else {
      toast.error(t(resp.message));
    }
  };

  const onChangeImage = (e) => {
    e.preventDefault();
    setQueryImage(false);
    setUserUploadImage(false);
    const queryImage = e.target.files[0];
    const validSizeMin = 10000; // 10KB
    const validSizeMax = 5000000; // 5MB
    const fileSize = queryImage && queryImage.size;
    if (fileSize < validSizeMin || fileSize > validSizeMax) {
      setImageError("File size should be 10KB to 5MB");
      return false;
    } else {
      setImageError("");
    }
    setUserUploadImage(URL.createObjectURL(queryImage));
    setImageError("");
    setQueryImage(queryImage);
  };

  const onChangeIssue = (event) => {
    const { name, value } = event.target;
    if (name === "query") {
      setQuery(value);
      setQueryIssue("");

      if (!value) {
        setQueryIssue("This field is required");
        setBtnDisable(false);
      } else {
        setQuery(value);
        setQueryIssue("");
      }
    }
  };

  function openModal() {
    document.getElementById("supportImageModel").style.display = "block";
  }

  // Function to close modal
  function closeModal() {
    console.log("console");
    document.getElementById("supportImageModel").style.display = "none";
  }

  // Function to set image source and open modal
  function setImageAndOpenModal(imageSrc) {
    console.log("imageSrc", imageSrc);
    // document.getElementById("modalImage").src = imageSrc;
    openModal();
  }

  const showTableHtml = () => {
    var stylediv = "";
    const html = [];
    let dateArray = [];
    if (tabledata.length > 0) {
      tabledata.map((value, i) => {
        if (value.queryImage != "") {
          var profilePic =
            imageUrl + "/static/supportImages/" + value.queryImage;
        }
        var date = "";

        if (value.replyBy == 1) {
          stylediv = "charting-part left_side_div";
          date = value.updatedAt;
        } else {
          stylediv = "charting-part right_side_div";
          date = value.createdAt;
        }
        let data = Moment(value.createdAt)
          .format("DD-MM-YYYY HH:mm:ss")
          .split(" ")[0];
        date = Moment(date).format("DD-MM-YYYY HH:mm:ss").split(" ");
        html.push(
          <div className={stylediv}>
            <div className="text-center">
              {Moment(dateTime).format("DD-MM-YYYY HH:mm:ss").split(" ")[0] ==
              data ? (
                <></>
              ) : (
                !dateArray.includes(data) && data
              )}
            </div>
            <div className="chatting-inner bg2">
              {value.queryImage && (
                <div className="msg-outer image-msg user-img-msg">
                  <a
                    href="!#"
                    data-toggle="modal"
                    data-target="#supportImageModel"
                    onClick={(e) => {
                      e.preventDefault();
                      setImage(profilePic);
                      setImageAndOpenModal(profilePic);
                    }}
                  >
                    <img src={profilePic} alt="" />
                  </a>
                </div>
              )}
              {value.replyBy == 1 ? (
                <div className="msg-outer user-msg">
                  <div>{value.query}</div>
                  <div className="msg-time">
                    <small>{date[1]}</small>
                  </div>
                </div>
              ) : (
                <div className="msg-outer">
                  <div dangerouslySetInnerHTML={{ __html: value.query }} />
                  <div className="msg-time">
                    <small>{date[1]}</small>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
        !dateArray.includes(data) && dateArray.push(data);
      });
    }
    return html;
  };

  const TicketList = async (event) => {
    var decodes = atob(tichekId);
    const userIdData = { ticketId: decodes };
    const res = await getSingleTIckitInfo(userIdData);
    if (res.status) {
      setTabledata1(res.data);
      setIssueText(res.data[0].issueType);
      setIssueImageSecond(res.data[0].issueImage);
      setDateTime(res.data[0].createAt);
      setResponseData(res.data[0].status);
      setSubject(res.data[0].issue);
      if (res.data[0].status == 1) {
        setOpenSteps("Chat_panel");
        setOpenStepsAll(false);
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className="bg2 border_all bl_0 br_0">
        <div className="container">
          <div className="profile_left_box pt-4">
            <h4 className="mb-2">{t("Chatting")}</h4>
            <div className="subject-status-outer d-md-flex  mb-3">
              <h6 className="">
                {t("Subject")}: {t(subject)}
              </h6>
              <h6 className="status-outer ml-auto">
                {t("Status")} :{" "}
                {ResponseData == 0 ? (
                  <i className="bi bi-clock" style={{ color: "red" }}>
                    {ResponseData == 0 && t("Pending")}
                  </i>
                ) : (
                  <i
                    className="fa fa-check-circle-o"
                    style={{ color: "green" }}
                  >
                    {t("Resolved")}
                  </i>
                )}{" "}
              </h6>
            </div>

            <div className=" chating-outer bg2 br20 ">
              {OpenSteps === "Chat_panel" || OpenStepsAll === "all" ? (
                <>
                  <div className=" ">
                    {tabledata1.length > 0 && (
                      <div className=" ">
                        <div className="left_chat_scrool bg1">
                          <div className="charting-part right_side_div">
                            <div className="text-center">
                              {
                                Moment(dateTime)
                                  .format("DD-MM-YYYY HH:mm:ss")
                                  .split(" ")[0]
                              }
                            </div>

                            <div className="chatting-inner bg2">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: issueText,
                                }}
                              />

                              {issueImageSecond && (
                                <div className="msg-outer image-msg user-img-msg ">
                                  <a
                                    href="#e"
                                    data-toggle="modal"
                                    data-target="#supportImageModel"
                                    onClick={() => {
                                      setImageAndOpenModal();
                                      setImage(
                                        imageUrl +
                                          "/static/supportImages/" +
                                          issueImageSecond
                                      );
                                    }}
                                  >
                                    <img
                                      src={
                                        imageUrl +
                                        "/static/supportImages/" +
                                        issueImageSecond
                                      }
                                      alt=""
                                    />
                                  </a>
                                </div>
                              )}
                              <div className="msg-time">
                                {
                                  Moment(dateTime)
                                    .format("YYYY-MM-DD HH:mm:ss")
                                    .split(" ")[1]
                                }
                              </div>
                            </div>
                          </div>
                          {showTableHtml()}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
              {OpenSteps === "Send_Message" || OpenStepsAll === "all" ? (
                <>
                  <div className="textarea_chatting">
                    <textarea
                      name="query"
                      value={query}
                      onChange={onChangeIssue}
                      className="form-control form_control_textarea"
                      placeholder={t("Enter Message")}
                      id="query"
                    />

                    <div className="input_uploader">
                      <input
                        className="profile_input"
                        type="file"
                        id="file-upload"
                        name="queryImage"
                        accept=".png, .jpg, .jpeg"
                        onChange={onChangeImage}
                      />
                      <i className="bi bi-paperclip"></i>
                    </div>

                    <button
                      onClick={chatSubmit}
                      className="btn btn_man "
                      disabled={btnDisable}
                    >
                      <i className="bi bi-send-fill"></i>
                    </button>
                  </div>
                  <p style={{ color: "red" }}>{t(queryIssue)}</p>
                  <p style={{ color: "red" }}>{t(imageError)}</p>

                  <label htmlFor="file-upload" id="file-drag">
                    <img
                      src={
                        userUploadImage !== false
                          ? userUploadImage
                          : verifyidpic
                      }
                      className="img-fluid22 support-img"
                      alt="Identity"
                    />
                  </label>
                </>
              ) : (
                <></>
              )}
              <div className="modal" id="supportImageModel">
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <span className="close mr-2" onClick={closeModal}>
                      &times;
                    </span>
                    <div className="modal-body p-0 pt-3">
                      <img className="img-fluid" src={image} alt="noimg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChattingFunction;
