import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import {
  useGetCryptoPortfolioQuery,
  useGetallUserQuery,
} from "../../redux/userApi";

import {
  useGetSelectedLeverageQuery,
  useGetUserForexPortfolioMutation,
} from "../../redux/forexApi";
import { useTranslation } from "react-i18next";
import { formatDecimal } from "../../util/decimalFormatter";
import { Modal } from "react-bootstrap";
import { useDepositRequestMutation } from "../../redux/accountApi";
import toast from "react-hot-toast";

const Dashboard = () => {
  const token = localStorage.getItem("jwtToken");
  const { t } = useTranslation();
  const { data } = useGetallUserQuery();
  const [depositRequest] = useDepositRequestMutation();
  const { data: cryptoPortfolio } = useGetCryptoPortfolioQuery({
    skip: !token,
  });
  const [userPortfolioForex] = useGetUserForexPortfolioMutation();
  const { data: selectedLeverages } = useGetSelectedLeverageQuery();
  // const [floatingProfits, setFloatingProfits] = useState(0.0);
  const [userLeverage, setUserLeverage] = useState("");
  const [usedBalance, setUsedBalance] = useState(0.0);
  const [totalBalance, setTotalBalance] = useState(0.0);
  const [freeBalance, setFreeBalance] = useState(0.0);
  const [isDepositModelOpen, setIsDepositModelOpen] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [image, setImage] = useState("");
  const [utr_id, setUtr_id] = useState("");
  const [errors, setErrors] = useState({
    depositAmount: "",
    paymentType: "",
    image: "",
    utr_id: "",
  });

  const username = data?.user_name || "N/A";

  useEffect(() => {
    if (token) {
      getEquityPortfolio();
    }
  }, []);

  const getEquityPortfolio = async () => {
    const data = {
      symbolType: "equity",
    };
    const res = await userPortfolioForex(data);
    const resp = res.data;
    if (resp.status === true) {
      setFreeBalance(resp.data.freeBalance ? resp.data.freeBalance : "0.0");
      setUsedBalance(resp.data.usedMargin ? resp.data.usedMargin : "0.0");
      setTotalBalance(resp.data.balance ? resp.data.balance : "0.0");
    }
  };

  useEffect(() => {
    if (selectedLeverages) {
      setUserLeverage(selectedLeverages.amount);
    } else {
      setUserLeverage(1);
    }
  }, [cryptoPortfolio, selectedLeverages]);

  const handleClose = () => {
    setIsDepositModelOpen(false);
  };

  const handlePaymentTypeChange = (e) => {
    const { name, value, files } = e.target;
    let newErrors = { ...errors };

    if (name === "depositAmount") {
      // Allow only positive numbers with optional decimals
      const amount = parseFloat(value);
      setDepositAmount(value);
      if (isNaN(amount) || amount <= 0) {
        newErrors.depositAmount = "Please enter a valid deposit amount.";
        return;
      } else {
        newErrors.depositAmount = "";
      }
    }

    if (name === "paymentType") {
      const validTypes = ["crypto", "UPI", "bank_transfer"];
      if (!validTypes.includes(value)) {
        newErrors.paymentType = "Invalid payment type selected.";
      } else {
        newErrors.paymentType = "";
        setPaymentType(value);
      }
    }

    if (name === "image") {
      const file = files[0];
      if (!file) {
        newErrors.image = "Please upload an image.";
      } else if (
        !["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      ) {
        newErrors.image = "Only JPG, PNG, or JPEG images are allowed.";
      } else if (file.size / 1024 / 1024 > 2) {
        newErrors.image = "File size should not exceed 2MB.";
      } else {
        newErrors.image = "";
        setImage(file);
      }
    }

    if (name === "utr_id") {
      const utr = value.trim();
      setUtr_id(utr);
      const utrRegex = /^[a-zA-Z0-9]{6,30}$/;
      if (!utrRegex.test(utr)) {
        newErrors.utr_id =
          "UTR/Transaction ID must be alphanumeric (6–30 characters).";
      } else {
        newErrors.utr_id = "";
      }
    }
    setErrors(newErrors);
  };

  const submitPaymentRequest = async () => {
    let newErrors = {};

    // Validate amount
    if (!depositAmount || parseFloat(depositAmount) <= 0) {
      newErrors.depositAmount = "Please enter a valid deposit amount.";
    }

    // Validate payment type
    const validTypes = ["crypto", "UPI", "bank_transfer"];
    if (!paymentType || !validTypes.includes(paymentType)) {
      newErrors.paymentType = "Please select a valid payment type.";
    }

    // Validate image (optional if required)
    if (!image) {
      newErrors.image = "Please upload a payment proof image.";
    }

    // Validate UTR / TID
    const utrRegex = /^[a-zA-Z0-9]{6,30}$/;
    if (!utr_id || !utrRegex.test(utr_id)) {
      newErrors.utr_id = "UTR/Transaction ID is required and must be valid.";
    }

    // If there are errors, set state and return
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // If no errors, clear previous ones
    setErrors({});

    const formData = new FormData();
    formData.append("amount", depositAmount);
    formData.append("paymentType", paymentType);
    formData.append("utr_id", utr_id);
    formData.append("depositImage", image);
    try {
      const result = await depositRequest(formData);

      if (result.data.status) {
        toast.success(result.data.message || "Deposit request submitted!");

        // ✅ Reset form fields
        setDepositAmount("");
        setPaymentType("");
        setUtr_id("");
        setImage(null);
        setErrors({});

        // ✅ Close modal
        setIsDepositModelOpen(false);
      } else {
        toast.error(result.data.message || "Server error. Please try again.");

        // Handle server-side errors
      }
    } catch (err) {
      console.error("Deposit error:", err);
    }
  };

  const copyToClipboard = () => {};

  return (
    <div>
      <Navbar />
      <section className="  border_all br_0 bl_0  min_h70vh">
        <div className="container ">
          <div className="p-3 p-md-4">
            <div className="row align-items-center pb-md-3">
              <div className="col-md-3">
                <h5 className="mb-3 mb-md-0">
                  {t("User ID")}:<span className="ml-3">{username}</span>
                </h5>
              </div>
              <div className="col-md-3">
                <h5 className="mb-3  mb-md-0">
                  {t("Leverage")}:
                  <span className="ml-3">
                    1:{userLeverage ? userLeverage : "1"}
                  </span>
                </h5>
              </div>
              <div className="col-md-6 text-md-right">
                <a
                  href="#!"
                  className="btn mr-3"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsDepositModelOpen(true);
                  }}
                >
                  Deposit
                </a>
                <a href="#" className="btn ">
                  Withdraw
                </a>
              </div>
            </div>

            <hr />
            <h5 className="mb-3 mt-4">{t("Portfolio")}</h5>
            <div className="row current_unds_item">
              <div className="col-6 col-md-4">
                <p className="mb-sm-2 mb-1">
                  {t("Total")} {t("Balance")}
                </p>
                <h4>
                  $
                  {isNaN(totalBalance)
                    ? "0.00"
                    : formatDecimal(totalBalance, 2)}
                </h4>
              </div>
              <div className="col-6 col-md-4">
                <p className="mb-sm-2 mb-1">
                  {t("Used")} {t("Balance")}
                </p>
                <h4>
                  ${isNaN(usedBalance) ? "0.00" : formatDecimal(usedBalance, 2)}
                </h4>
              </div>
              <div className="col-6 col-md-4">
                <p className="mb-sm-2 mb-1">
                  {t("Free")} {t("Balance")}
                </p>
                <h4>
                  ${isNaN(freeBalance) ? "0.00" : formatDecimal(freeBalance, 2)}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={isDepositModelOpen}
          onHide={handleClose}
          className="account-popup-outer"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title className="mb-0"> {t("Deposit")}</Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleClose}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row align-items-center mb-3">
              <div className="form-group col mb-0">
                <label className=" ">{t("Select Payment Type")}</label>
                <select
                  className="form-control"
                  name="paymentType"
                  value={paymentType}
                  onChange={handlePaymentTypeChange}
                >
                  <option value="">{t("Select")}</option>
                  <option value="crypto">{t("Crypto")}</option>
                  <option value="bank_transfer">{t("Bank Transfer")}</option>
                  <option value="UPI">{t("UPI")}</option>
                </select>
                {errors.paymentType && (
                  <small className="text-danger">{errors.paymentType}</small>
                )}
              </div>
            </div>

            <div className="form-group">
              <label className=" ">{t("Amount")}</label>
              <div className="position-relative">
                <input
                  step="any"
                  className="form-control pr-5"
                  name="depositAmount"
                  value={depositAmount}
                  onChange={handlePaymentTypeChange}
                  placeholder={t("Enter Amount")}
                  type="number"
                />
              </div>
              {errors.depositAmount && (
                <small className="text-danger">{errors.depositAmount}</small>
              )}
            </div>
            <div className="form-group">
              <label className=" ">{t("Upload Image")}</label>
              <div className="position-relative">
                <input
                  className="form-control pr-5"
                  name="image"
                  onChange={handlePaymentTypeChange}
                  type="file"
                />
              </div>
              {errors.image && (
                <small className="text-danger">{errors.image}</small>
              )}
            </div>
            <div className="form-group">
              <label className=" ">{t("UTR/TID")}</label>
              <div className="position-relative">
                <input
                  step="any"
                  className="form-control pr-5"
                  name="utr_id"
                  value={utr_id}
                  onChange={handlePaymentTypeChange}
                  placeholder={t("Utr number / Transaction number")}
                  type="text"
                />
              </div>
              {errors.utr_id && (
                <small className="text-danger">{errors.utr_id}</small>
              )}
            </div>

            <div className=" mb-3 b_sh">
              {paymentType === "crypto" ? (
                <>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <strong>Wallet Address:</strong>
                    <span className="text-monospace">
                      0x7dd0658E06fa1a87E4B974B0a739B9CDBC8a3790
                      <i
                        className="bi bi-clipboard ml-2 cursor-pointer"
                        title="Copy"
                      />
                    </span>
                  </div>
                  <img
                    src="images/qr-code.png"
                    className="d-block m-auto img-fluid"
                    alt="qr-code"
                  />
                </>
              ) : paymentType === "bank_transfer" ? (
                <>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <strong>Account Number:</strong>
                    <span className="text-monospace ">
                      1234567890
                      <i
                        className="bi bi-clipboard ml-2 cursor-pointer"
                        onClick={() => copyToClipboard("1234567890")}
                        title="Copy"
                      />
                    </span>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <strong>IFSC Code:</strong>
                    <span className="text-monospace ">
                      HDFC0001234
                      <i
                        className="bi bi-clipboard ml-2 cursor-pointer"
                        onClick={() => copyToClipboard("HDFC0001234")}
                        title="Copy"
                      />
                    </span>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <strong>Account Holder:</strong>
                    <span className="text-monospace ">
                      MyMetaFx
                      <i
                        className="bi bi-clipboard ml-2 cursor-pointer"
                        onClick={() => copyToClipboard("MyMetaFx")}
                        title="Copy"
                      />
                    </span>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <strong>Bank Name:</strong>
                    <span className="text-monospace ">
                      HDFC Bank
                      <i
                        className="bi bi-clipboard ml-2 cursor-pointer"
                        onClick={() => copyToClipboard("HDFC Bank")}
                        title="Copy"
                      />
                    </span>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <strong>Branch:</strong>
                    <span className="text-monospace ">
                      HDFC Bank
                      <i
                        className="bi bi-clipboard ml-2 cursor-pointer"
                        onClick={() => copyToClipboard("HDFC Bank")}
                        title="Copy"
                      />
                    </span>
                  </div>
                </>
              ) : paymentType === "UPI" ? (
                <>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <strong>UPI ID:</strong>
                    <span className="text-monospace">
                      mymetafx@hdfcbank
                      <i
                        className="bi bi-clipboard ml-2 cursor-pointer ml-2"
                        title="Copy"
                      />
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <strong>Account Holder Name:</strong> MyMetaFx
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <strong>Bank Name:</strong> HDFC Bank
                  </div>
                  <img
                    src="images/qr-code.png"
                    alt="QR Code"
                    className="img-fluid mw35 mx-auto d-block mt-2"
                  />
                </>
              ) : (
                ""
              )}
            </div>
            <div className="tc">
              <button
                className="btn btn_man fs14 w100"
                onClick={submitPaymentRequest}
                // disabled={isLoading}
              >
                {t("Deposit")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </section>
      <Footer />
    </div>
  );
};

export default Dashboard;
