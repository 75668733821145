import React, { useEffect, useRef, memo } from "react";
import { useParams } from "react-router-dom";

function TradingViewWidget({ theme = "light" }) {
  const container = useRef(null);
  const { symbol } = useParams();

  useEffect(() => {
    initChart();
  }, [symbol, theme]); // Ensure this effect runs only once when the component mounts

  const initChart = () => {
    const pair = symbol;
    const currentContainer = container.current;
    currentContainer.innerHTML = "";

    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.crossOrigin = "anonymous";
    script.innerHTML = JSON.stringify({
      autosize: true,
      timezone: "America/New_York",
      symbol: `${pair}`,
      interval: "D",
      theme: theme,
      style: "8",
      locale: "en",
      enable_publishing: false,
      withdateranges: true,
      hide_side_toolbar: false,
      allow_symbol_change: true,
      calendar: false,
      support_host: "https://www.tradingview.com",
      // height: 320,
      // width: "100%",
    });

    currentContainer.appendChild(script);
  };

  return <div ref={container} id="tv_chart_container"></div>;
}

export default memo(TradingViewWidget);
