import React, { useEffect, useState } from "react";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
// import Sidebar from "../widgets/Sidebar";
import { useGetUserActivityQuery } from "../../redux/userApi";
import moment from "moment";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";

const UserActivity = () => {
  const { t } = useTranslation();

  const [cryptoTradeDataApi, setCryptoTradeDataApi] = useState([]);
  const { data, isLoading } = useGetUserActivityQuery();

  return (
    <div>
      <Navbar />
      <section className="  border_all br_0 bl_0  min_h70vh">
      <div className="container">
        
              <div className="p-3 p-md-4"> 
                  <div className="align-items-center mb-2">
                    <h5 className="nav-item  "> 
                      {t("User Activity")}
                    </h5>
                  </div>
                  <div className="table-responsive trade_scrool_table trade_scrool_table22">
                    {data && data.length > 0
                      ? data.map((act, i) => (
                          <>
                            <div className=" d-flex justify-content-center">
                              <div className="card">
                                <div className="media">
                                  <div className="media-body">
                                    <h6 className="mt-2 mb-1 act-title">
                                      {t(act.title)}
                                    </h6>
                                    <small className="text">
                                      {moment(act.created_at).fromNow()}
                                    </small>
                                    <p className="mb-2">
                                      <small>{t(act.message)}</small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {i !== data.length - 1 && <hr />}
                          </>
                        ))
                      : `${t("No Data Found")}!`}
                  </div> 
              </div> 
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default UserActivity;
