import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import socketIOClient from "socket.io-client";
import ExchangeNavBar from "../widgets/ExchangeNavBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { imageUrl, socketUrl } from "../constant/BaseUrl";
import DocumentMeta from "react-document-meta";
import { ProductName } from "../constant/ProductName";
import TradingViewForexWidgit from "../partials/TradingViewForexWidgit";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import Decimal from "decimal.js";
import toast from "react-hot-toast";
import update from "immutability-helper";
import {
  useCancleOrderFxMutation,
  useClosePositionMutation,
  useCreateOrderClickMutation,
  useGetSymbolInfoMutation,
  useGetSelectedLeverageQuery,
  useModifyOrderClickMutation,
  usePartialClosePositionForexMutation,
} from "../../redux/forexApi";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import BigNumber from "bignumber.js";
import {
  useGetallUserQuery,
  useGetUserWidgetsQuery,
} from "../../redux/userApi";
import DraggableItem from "./DraggableItem";
import pako from "pako";
import { Buffer } from "buffer";
import "../../App.css";
import { useTranslation } from "react-i18next";
import { formatDecimal, formatTakeProfit } from "../../util/decimalFormatter";
import UserTicker from "../partials/UserTicker";
import Version from "../partials/Version";
const ResponsiveGridLayout = WidthProvider(Responsive);

const layouts = {
  xl: [
    { i: "topbar", x: 0, y: 0.05, w: 12, h: 1.5, static: true },
    {
      i: "pairslist",
      x: 0,
      y: 1.65,
      w: 1.989,
      h: 19,
      static: true,
    },
    { i: "buy", x: 12, y: 1.65, w: 1.989, h: 19, static: true },
    { i: "chart", x: 2, y: 1.65, w: 7.998, h: 19, static: true },
    { i: "marketdepth", x: 0, y: 20.7, w: 1.989, h: 12, static: true },
    { i: "orderdetails", x: 2, y: 20.7, w: 10, h: 12, static: true },
  ],
  lg: [
    { i: "topbar", x: 0, y: 0.05, w: 12, h: 1.5, static: true },
    {
      i: "pairslist",
      x: 0,
      y: 1.65,
      w: 2.989,
      h: 19,
      static: true,
    },
    { i: "buy", x: 12, y: 1.65, w: 2.989, h: 19, static: true },
    { i: "chart", x: 3, y: 1.65, w: 5.992, h: 19, static: true },
    { i: "marketdepth", x: 0, y: 20.7, w: 2.989, h: 12, static: true },
    { i: "orderdetails", x: 3, y: 20.7, w: 9, h: 12, static: true },
  ],
  md: [
    { i: "topbar", x: 0, y: 0.05, w: 12, h: 1.5, static: true },
    {
      i: "pairslist",
      x: 9,
      y: 1.65,
      w: 2.987,
      h: 14.3,
      static: true,
    },
    { i: "buy", x: 9, y: 15.989, w: 2.987, h: 18, static: true },
    { i: "chart", x: 0, y: 1.65, w: 7, h: 14.3, static: true },
    { i: "marketdepth", x: 9, y: 34, w: 2.987, h: 11, static: true },
    { i: "orderdetails", x: 0, y: 15.989, w: 7, h: 28.99, static: true },
  ],
  sm: [
    { i: "topbar", x: 0, y: 0.05, w: 12, h: 1.95, static: true },
    {
      i: "pairslist",
      x: 10,
      y: 2.1,
      w: 1.987,
      h: 15.85,
      static: true,
    },
    { i: "buy", x: 9, y: 9, w: 1.987, h: 19, static: true },
    { i: "chart", x: 0, y: 2.1, w: 4, h: 15.85, static: true },
    { i: "marketdepth", x: 0, y: 18, w: 4, h: 19, static: true },
    { i: "orderdetails", x: 0, y: 37.1, w: 6, h: 18, static: true },
  ],
  xs: [
    { i: "topbar", x: 0, y: 0.05, w: 12, h: 7.2, static: true },
    { i: "pairslist", x: 0, y: 7.35, w: 12, h: 15, static: true },
    { i: "chart", x: 0, y: 22.39, w: 12, h: 12, static: true },
    { i: "buy", x: 0, y: 32.45, w: 12, h: 18, setIndex: 6, static: true },
    { i: "marketdepth", x: 0, y: 52.5, w: 12, h: 11, static: true },
    { i: "orderdetails", x: 0, y: 63.6, w: 12, h: 16, static: true },
  ],
};

const getFromLocalStorage = (key) => {
  if (typeof window !== "undefined") {
    const saved = localStorage.getItem(key);
    return saved ? JSON.parse(saved) : null;
  }
  return null;
};

const saveToLocalStorage = (key, value) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

const defaultError = {
  price: "",
  stopPrice: "",
  takeProfit: "",
};

export const ContextMenu = ({ x, y, onAction, menuRef }) => {
  return (
    <div ref={menuRef} className="context-menu bg2" style={{ top: y, left: x }}>
      <button onClick={() => onAction("close")}>
        <span>Close</span> <i className="bi bi-x-circle"></i>
      </button>
      <button onClick={() => onAction("modify")}>
        <span>Modify</span> <i className="bi bi-pencil-square"></i>
      </button>
    </div>
  );
};

const ForexExchange = (props) => {
  const localSocket = useRef(null);
  const { t } = useTranslation();
  const { symbol } = useParams();
  const { data } = useGetallUserQuery();
  const navigate = useNavigate();
  const [createOrderOneClick, { isLoading }] = useCreateOrderClickMutation();
  const [partialOrderClose] = usePartialClosePositionForexMutation();
  const { data: userWidgets } = useGetUserWidgetsQuery({ type: "forex" });
  const [modifyOrderClick] = useModifyOrderClickMutation();
  const [getSymbolInfo] = useGetSymbolInfoMutation();
  const [closeOrderFx, { isLoading: positionClosing }] =
    useClosePositionMutation();
  const [cancleOrderFx] = useCancleOrderFxMutation();
  const [coinPairData, setCoinPairData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [userLeverage, setUserLeverage] = useState("");
  const [accountPnL, setAccountPnL] = useState(new BigNumber(0));
  const [sideAcvive, setSideAcvive] = useState("buy");
  const [theme, setTheme] = useState("light");
  const [activeOrderTab, setActiveOrderTab] = useState("positions");
  const [openOrders, setOpenOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [closedOrders, setClosedOrders] = useState([]);
  const [cancleOrders, setCancleOrders] = useState([]);
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState(0.01);
  const [allOrdersData, setAllOrdersData] = useState([]);
  const [selectedAsk, setSelectedAsk] = useState("");
  const [selectedBid, setSelectedBid] = useState("");
  const [stopPrice, setStopPrice] = useState("");
  const [isStopLoss, setIsStopLoss] = useState(false);
  const [userBalance, setUserBalance] = useState("0.00");
  const [margin, setMargin] = useState("0.00");
  const [freeMargin, setFreeMargin] = useState("0.00");
  const [marginLevel, setMarginLevel] = useState("0.00");
  const [floatingProfits, setFloatingProfits] = useState(new BigNumber(0));
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [takeProfit, setTakeProfit] = useState("");
  const [isTakeProfit, setIsTakeProfit] = useState(false);
  const [orderMethod, setOrderMethod] = useState("market");
  const [refresh, setRefresh] = useState(false);
  const [activeTab, setActiveTabModify] = useState("modify");
  const [volumeToClose, setVolumeToClose] = useState(0.0);
  const [takeProfitAmount, setTakeProfitAmount] = useState(0);
  const [currentselectedCoin, setCurrentselectedCoin] = useState({});
  const [relizedProfit, setrelizedProfit] = useState(0);
  const [stopLosspl, setStopLosspl] = useState(0);

  useEffect(() => {
    if (sideAcvive === "buy" && stopPrice > 0) {
      setStopLosspl((stopPrice - displayPrice).toFixed(4));
    } else if (sideAcvive === "sell" && stopPrice > 0) {
      setStopLosspl((displayPrice - stopPrice).toFixed(4));
    } else {
      setStopLosspl(0);
    }
  }, [stopPrice]);

  useEffect(() => {
    if (sideAcvive === "buy" && takeProfit > 0) {
      setTakeProfitAmount((takeProfit - displayPrice).toFixed(4));
    } else if (sideAcvive === "sell" && takeProfit > 0) {
      setTakeProfitAmount((displayPrice - takeProfit).toFixed(4));
    } else {
      setTakeProfitAmount(0);
    }
  }, [takeProfit]);

  const [layout, setLayout] = useState(
    getFromLocalStorage("layouts") || layouts
  );
  const { data: selectedLeverages } = useGetSelectedLeverageQuery();
  const [removedItems, setRemovedItems] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [marketDepth, setMarketDepth] = useState([
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85915,
      MDEntrySize: 500000,
      QuoteEntryID: "0",
    },
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85914,
      MDEntrySize: 1600000,
      QuoteEntryID: "1",
    },
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85913,
      MDEntrySize: 4100000,
      QuoteEntryID: "2",
    },
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85912,
      MDEntrySize: 3000000,
      QuoteEntryID: "3",
    },
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85911,
      MDEntrySize: 3500000,
      QuoteEntryID: "4",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.8593,
      MDEntrySize: 500000,
      QuoteEntryID: "0",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.85932,
      MDEntrySize: 4600000,
      QuoteEntryID: "1",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.85933,
      MDEntrySize: 16000000,
      QuoteEntryID: "2",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.85934,
      MDEntrySize: 10500000,
      QuoteEntryID: "3",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.85935,
      MDEntrySize: 5000000,
      QuoteEntryID: "4",
    },
  ]);
  const [currentRightClickData, setCurrentRightClickData] = useState(null);
  const menuRef = useRef(null);
  const [monetary, setMonetary] = useState(new BigNumber(0));
  const [errors, setErrors] = useState(defaultError);
  const [show, setShow] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [notificationData, setNotificationData] = useState([]);
  const [currentOrderClosing, setCurrentClosingOrder] = useState(null);
  const [notAllowedWidgets, setNotAllowedWidgets] = useState([]);
  const [buyCommission, setBuyCommission] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [sellCommission, setSellCommission] = useState(0);
  const [feesType, setFeesType] = useState("");
  const [percentAmount, setPercentAmount] = useState(0);
  const [feeAmyt, setFeeAmyt] = useState("");
  const [floatingProfitsClose, setFloatingProfitsClose] = useState(
    new BigNumber(0)
  );
  const [ordersCount, setOrdersCount] = useState(0);
  const [priceMap, setPriceMap] = useState({}); // Optimized hashmap for quick lookups
  const [orderId, setOrderId] = useState("");
  const [displayPrice, setDisplayPrice] = useState("");

  useEffect(() => {
    if (selectedLeverages) {
      if (selectedLeverages) {
        setUserLeverage(selectedLeverages.amount);
      } else {
        setUserLeverage(1);
      }
    }
  }, [selectedLeverages]);

  useEffect(() => {
    if (Object.keys(currentselectedCoin).length !== 0) {
      let order_side = currentselectedCoin?.order_side;
      let price = Number(currentselectedCoin?.price);
      let currentPrice;
      if (order_side === "SELL") {
        currentPrice = Number(currentselectedCoin?.sellPrice);
      } else {
        currentPrice = Number(currentselectedCoin?.buyPrice);
      }
      let realVolume = Number(currentselectedCoin?.quantity);
      const currentSymbol = currentselectedCoin?.symbol;
      const segment = currentselectedCoin.symbol_type;
      const profit = volumeWiseOrderPriceCalculation(
        volumeToClose,
        order_side,
        currentPrice,
        price,
        realVolume,
        currentSymbol,
        segment
      );

      if (!isNaN(profit)) {
        setrelizedProfit(profit.toFixed(3));
      } else {
        setrelizedProfit(0.0);
      }
    }
  }, [volumeToClose]);

  function volumeWiseOrderPriceCalculation(
    quntitys,
    order_side,
    runningPrice,
    clientPrice,
    realQuntity,
    symbol,
    symbol_type
  ) {
    if (quntitys === 0) {
      return 0;
    }
    if (quntitys > realQuntity) {
      return 0;
    }
    let priceChange;
    if (order_side === "BUY") {
      priceChange = runningPrice - clientPrice;
    } else {
      priceChange = clientPrice - runningPrice;
    }
    const quantity = parseFloat(quntitys);
    let floatingProfit;
    if (symbol.startsWith("XAU") || symbol.startsWith("XAG")) {
      floatingProfit = priceChange * (quantity * 100);
    } else if (symbol_type === "forex") {
      const quantitys = quantity * parseFloat(100000);
      if (symbol.endsWith("JPY")) {
        floatingProfit = (priceChange * quantitys) / runningPrice;
      } else {
        floatingProfit = priceChange * quantitys;
      }
    }
    return floatingProfit;
  }

  useEffect(() => {
    if (userWidgets) {
      const disabled = Object.keys(userWidgets).filter(
        (key) => userWidgets[key] === "0"
      );
      if (disabled.length) {
        const removeItemsLocal = getFromLocalStorage("frl") || [];

        setNotAllowedWidgets(disabled);
        let widgetToBeRemoved = [
          ...new Set(
            removeItemsLocal.filter((item) => disabled.includes(item))
          ),
        ];

        let widgetToBeAdded = [
          ...new Set(
            removeItemsLocal.filter((item) => !disabled.includes(item))
          ),
        ];

        widgetToBeAdded.map((item) => addItem(item));

        saveToLocalStorage("frl", widgetToBeRemoved);
      }

      if (!disabled.length) {
        const removeItemsLocal = getFromLocalStorage("frl") || [];
        removeItemsLocal.map((item) => addItem(item));

        saveToLocalStorage("frl", []);
      }
    }
  }, [userWidgets]);

  const handleDoubleClick = (rowData) => {
    document.body.classList.add("model-segment");
    if (positionClosing) return;
    setCurrentselectedCoin(rowData);
    setIsStopLoss(true);
    setIsTakeProfit(true);
    setOrderId(rowData.id);
    setDisplayPrice(rowData.price);
    setSideAcvive(rowData.order_side.toLowerCase());
    setPrice(rowData.price);
    setStopPrice(rowData.stop_price);
    setTakeProfit(rowData.take_profit);
    setSelectedRowData(rowData);
    setShow(true);
    handleSocketMessages(localSocket, rowData.symbol);
  };

  const handleClose = () => {
    document.body.classList.remove("model-segment");
    setShow(false);
    setTakeProfit("");
    setSelectedRowData(null);
    setActiveTabModify("modify");
    setVolumeToClose(0.0);
    setCurrentselectedCoin({});
    setrelizedProfit(0);
    handleSocketMessages(localSocket, symbol);
  };

  const result = useMemo(() => {
    const qutiy = new BigNumber(quantity ? quantity : 0);
    return qutiy.multipliedBy(100000);
  }, [quantity]);

  useEffect(() => {
    setMonetary(result);
  }, [result]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLayoutChange = (layout, allLayouts) => {
    setLayout(allLayouts);
    saveToLocalStorage("layouts", allLayouts);
  };

  useEffect(() => {
    const savedLayouts = getFromLocalStorage("layouts");
    if (savedLayouts) {
      setLayout(savedLayouts);
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await getSymbolInfo({ symbol });
      if (data?.status) {
        setSelectedBid(data.data.best_bid);
        setSelectedAsk(data.data.best_ask);
        setPrice(data.data.best_bid);
      }
    };
    fetch();
  }, [symbol]);

  useEffect(() => {
    if (removedItems.length > 0) {
      saveToLocalStorage("frl", removedItems);
    }
  }, [removedItems]);

  useEffect(() => {
    const removeItemsLocal = getFromLocalStorage("frl");
    if (removeItemsLocal) {
      setRemovedItems(removeItemsLocal);
    }
  }, []);

  useEffect(() => {
    let subSymbol = symbol;
    localStorage.setItem("sa-tabs-fx", JSON.stringify(subSymbol));
    if (!localSocket.current) {
      const mainState = {
        symbolType: "forex",
      };

      localSocket.current = socketIOClient(socketUrl, {
        transports: ["websocket"],
        query: {
          token: localStorage.getItem("jwtToken"),
        },
      });
      localSocket.current.on("connect", () => {
        const newSymbol = JSON.parse(localStorage.getItem("sa-tabs-fx"));
        if (symbol !== newSymbol) {
          subSymbol = newSymbol;
        }
        const symbolData = {
          symbol: subSymbol,
          symbolType: "forex",
        };
        console.log("Socket is connected");
        localSocket.current.emit("ping");
        localSocket.current.emit("getForexSymbol");
        localSocket.current.emit("userPendingOrderFX", mainState);
        localSocket.current.emit("userCloseOrderFX", mainState);
        localSocket.current.emit("userOpenOrderFX", mainState);
        localSocket.current.emit("userAllOrdersPandL", mainState);
        localSocket.current.emit("userCancleOrderFX", mainState);
        localSocket.current.emit("userBalanceById", mainState);
        localSocket.current.emit("subscribe", subSymbol);
        localSocket.current.emit("userAllOrderFX");
        localSocket.current.emit("notificationLogsFx");
        localSocket.current.emit("getCommissionBySymbol", symbolData);
        handleSocketMessages(localSocket, subSymbol);
      });
    }
    return () => {
      if (
        localSocket.current &&
        !window.location.pathname.includes("/forex/")
      ) {
        console.log("WebSocket connection will be closed on unmount.");
        localSocket.current.disconnect();
      }
    };
  }, []);

  const mergeDataWithOrder = (newData, savedOrder) => {
    const orderMap = new Map();
    savedOrder.forEach((id, index) => {
      orderMap.set(id, index);
    });

    newData.sort((a, b) => {
      const orderA = orderMap.get(a.id) ?? newData.length;
      const orderB = orderMap.get(b.id) ?? newData.length;
      return orderA - orderB;
    });

    return newData;
  };

  const handleSocketMessages = (localSocket, selectedSymbol) => {
    localSocket.current.on("pong", () => {});
    localSocket.current.off("resultForexSymbol");
    localSocket.current.on("resultForexSymbol", (compressedData) => {
      try {
        // Decompress the data using pako
        const compressedBuffer = Buffer.from(compressedData, "base64");
        const decompressedData = pako.inflate(compressedBuffer, {
          to: "string",
        });
        const data = JSON.parse(decompressedData);
        // Your existing logic
        const selectedSymbolData = data.filter(
          (sym) => sym.symbol === selectedSymbol
        );
        if (selectedSymbolData.length > 0) {
          setSelectedBid(selectedSymbolData[0].best_bid);
          setSelectedAsk(selectedSymbolData[0].best_ask);
        }

        // Create a hashmap for quick lookups
        const priceDataMap = {};
        data.forEach((coin) => {
          const key = `${coin.symbol}`;
          priceDataMap[key] = {
            best_bid: coin.best_bid,
            best_ask: coin.best_ask,
          };
        });
        setPriceMap(priceDataMap); // Store the hashmap in state

        const savedOrder = JSON.parse(localStorage.getItem("fso")) || [];
        const mergedData = mergeDataWithOrder(data, savedOrder);

        setCoinPairData(mergedData);
      } catch (error) {
        console.error("Decompression error:", error);
      }
    });
    localSocket.current.off("userPendingOrderFXResult");
    localSocket.current.on("userPendingOrderFXResult", (data) => {
      setPendingOrders(data);
    });
    localSocket.current.off("userCloseOrderFXResult");
    localSocket.current.on("userCloseOrderFXResult", (data) => {
      setClosedOrders(data);
    });
    localSocket.current.off("userOpenOrderFXResult");
    localSocket.current.on("userOpenOrderFXResult", (data) => {
      setOpenOrders(data.orders);
      setOrdersCount(data.totalOrders);
    });
    localSocket.current.off("resultAllOrdersPandL");
    localSocket.current.on("resultAllOrdersPandL", (data) => {
      setFloatingProfits(data.totalFloatingProfit);
      setAccountPnL(data.accountProfitLoss);
    });
    localSocket.current.off("userCancleOrderFXResult");
    localSocket.current.on("userCancleOrderFXResult", (data) => {
      setCancleOrders(data);
    });
    localSocket.current.off("resultCommissionBySymbol");
    localSocket.current.on("resultCommissionBySymbol", (data) => {
      setFeesType(data.type ? data.type : "");
      setBuyCommission(data.buyFees ? data.buyFees : "0");
      setSellCommission(data.sellFees ? data.sellFees : "0");
    });
    localSocket.current.off("notificationLogsResultFx");
    localSocket.current.on("notificationLogsResultFx", (data) => {
      setNotificationData(data);
    });
    localSocket.current.off("userAllOrderFXResult");
    localSocket.current.on("userAllOrderFXResult", (data) => {
      setAllOrdersData(data);
    });
    localSocket.current.off("resultUserBalanceById");
    localSocket.current.on("resultUserBalanceById", (data) => {
      if (data?.status) {
        const balanceMetrics = data.data;
        setUserBalance(balanceMetrics.balance);
        setMargin(balanceMetrics.forexMargin);
        setFreeMargin(balanceMetrics.freeMargin);
        setMarginLevel(balanceMetrics.marginLevel);
      } else {
        setUserBalance("0.00");
        setMargin("0.00");
        setFreeMargin("0.00");
        setMarginLevel("0.00");
      }
    });
    localSocket.current.off(`marketDepth:${symbol}`);
    localSocket.current.on(`marketDepth:${selectedSymbol}`, (data) => {
      const marketDepth = JSON.parse(data);
      setMarketDepth(marketDepth);
    });

    localSocket.current.off("resultCommissionBySymbol");
    localSocket.current.on("resultCommissionBySymbol", (data) => {
      setFeesType(data.type ? data.type : "");
      setBuyCommission(data.buyFees ? data.buyFees : "0");
      setSellCommission(data.sellFees ? data.sellFees : "0");
    });
    localSocket.current.off(`logout_session`);
    localSocket.current.on(`logout_session`, async (data) => {
      localStorage.clear();
      setTimeout(() => {
        window.location.href = "/login";
      }, 2000);
    });
    localSocket.current.off("refresh");
    localSocket.current.on("refresh", () => {
      const mainState = {
        symbolType: "forex",
      };
      localSocket.current.emit("ping");
      localSocket.current.emit("userCloseOrderFX", mainState);
      localSocket.current.emit("userCancleOrderFX", mainState);
      localSocket.current.emit("userBalanceById", mainState);
      localSocket.current.emit("userOpenOrderFX", mainState);
      localSocket.current.emit("userPendingOrderFX", mainState);
      localSocket.current.emit("userAllOrderFX");
      handleSocketMessages(localSocket, selectedSymbol);
    });

    localSocket.current.off("orderRefresh");
    localSocket.current.on("orderRefresh", () => {
      const mainState = {
        symbolType: "forex",
      };
      localSocket.current.emit("userCancleOrderFX");
      localSocket.current.emit("userCloseOrderFX", mainState);
      handleSocketMessages(localSocket, selectedSymbol);
    });
  };

  const onSearch = (event) => {
    setSearchedValue(event.target.value);
  };

  const EmptySearch = () => {
    setSearchedValue("");
  };

  const topBarHtml = () => {
    const balance = new BigNumber(userBalance);
    const profit = new BigNumber(floatingProfits);

    // Equity is the sum of the account balance and the floating profits
    const equityPrice = balance.plus(profit);

    return (
      <UserTicker
        translate={t}
        balance={userBalance}
        equityPrice={equityPrice}
        leverage={userLeverage}
        usedMargin={margin}
        freeMargin={freeMargin}
        profit={accountPnL}
      />
    );
  };

  const handlePairClick = (pairName) => {
    localStorage.setItem("sa-tabs-fx", JSON.stringify(pairName.symbol));

    navigate(`/forex/${pairName.symbol}`);
    setCurrentPrice(pairName.best_bid);
    setOrderMethod("market");
    setIsStopLoss(false);
    setIsTakeProfit(false);
    const symbolData = {
      symbol: pairName.symbol,
      symbolType: "forex",
    };
    handleSocketMessages(localSocket, pairName.symbol);
    localSocket.current.emit("getCommissionBySymbol", symbolData);
    localSocket.current.emit("unsubscribe", symbol);
    localSocket.current.emit("subscribe", pairName.symbol);
  };

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const draggedItem = coinPairData[dragIndex];
      const newData = update(coinPairData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, draggedItem],
        ],
      });
      setCoinPairData(newData);
      const onlyIds = newData.map((item) => item.id);
      localStorage.setItem("fso", JSON.stringify(onlyIds));
    },
    [coinPairData]
  );

  const assetsListHtml = () => {
    const filteredItems = coinPairData
      .filter((item) => item.symbol.includes(searchedValue.toUpperCase()))
      .filter((item) => {
        if (selectedFilter === "All") return true;
        return item.symbol_cat?.toLowerCase() === selectedFilter.toLowerCase();
      });
    let moreFilteredItems = filteredItems;

    if (selectedFilter === "All" || selectedFilter === "Forex") {
      let priorityCurrencies = [
        "EURUSD",
        "USDJPY",
        "GBPUSD",
        "USDCHF",
        "AUDUSD",
        "USDCAD",
        "NZDUSD",
        "EURGBP",
        "EURJPY",
        "EURCHF",
        "XAUUSD",
        "XAGUSD",
      ];

      const sortFunc = filteredItems.sort((a, b) => {
        let indexA = priorityCurrencies.indexOf(a.symbol);
        let indexB = priorityCurrencies.indexOf(b.symbol);

        // If both are in the priority list, sort by their index in the priority list
        if (indexA !== -1 && indexB !== -1) return indexA - indexB;
        // If only a is in the priority list, a comes first
        if (indexA !== -1) return -1;
        // If only b is in the priority list, b comes first
        if (indexB !== -1) return 1;
        // If neither are in the priority list, maintain original order
        return 0;
      });

      moreFilteredItems = sortFunc;
    }
    return (
      <tbody>
        {moreFilteredItems.map((pair, index) => {
          const bestAsk = new Decimal(pair.best_ask);
          const bestBid = new Decimal(pair.best_bid);
          const spread = bestAsk.minus(bestBid);
          const showActive = symbol === pair.symbol;

          const htmlPart = (
            <>
              <td className="r-market-pair ng-binding fw600">
                {pair.icon && (
                  <img
                    src={imageUrl + "/static/currencyImage/" + pair.icon}
                    alt="icon"
                    className="currency_icon"
                  />
                )}{" "}
                {pair.symbol}
              </td>
              <td className="r-market-price ng-binding fw600">
                {spread.toString()}
              </td>
              <td className="r-market-rate text-right">
                <span
                  className="ng-binding green"
                  style={{ color: "rgb(0, 188, 139)" }}
                >
                  {pair.best_ask}
                  <br /> {pair.best_bid}
                </span>
              </td>
            </>
          );

          if (pair.symbol_type === "equity") return null;

          return (
            <>
              <DraggableItem
                key={pair.id}
                index={index}
                id={pair.id}
                text={htmlPart}
                moveItem={moveItem}
                active={showActive}
                onClick={() => handlePairClick(pair)}
              />
            </>
          );
        })}
      </tbody>
    );
  };

  const handleBuyOrder = async (e) => {
    e.preventDefault();
    const orderObj = {
      orderSide: sideAcvive === "buy" ? "BUY" : "SELL",
      orderPrice: sideAcvive === "buy" ? selectedAsk : selectedBid,
      orderQuantity: quantity,
      orderPair: symbol,
      orderType: "market",
      status: "open",
      symbolSide: "forex",
      stopPrice: stopPrice ? stopPrice : null,
      takeProfit: takeProfit ? takeProfit : null,
    };
    const res = await createOrderOneClick(orderObj);
    if (res.data.success) {
      toast.success(t(res.data.message));
      setRefresh((prev) => !prev);
    } else {
      toast.error(t(res.data.message));
    }
  };

  const handleChartOrderMargin = async (side) => {
    const orderObj = {
      orderSide: side,
      orderPrice: side === "BUY" ? selectedAsk : selectedBid,
      orderQuantity: quantity,
      orderPair: symbol,
      orderType: "market",
      status: "open",
      symbolSide: "forex",
    };
    const res = await createOrderOneClick(orderObj);
    if (res.data.success) {
      toast.success(t(res.data.message));
      setRefresh((prev) => !prev);
    } else {
      toast.error(t(res.data.message));
    }
  };

  const handleCloseOrder = async (orderInfo, flotingProfit, closePrice) => {
    if (positionClosing) return;
    setCurrentClosingOrder(orderInfo);
    const orderObj = {
      orderId: orderInfo.id,
      orderPair: orderInfo.symbol,
      quantity: orderInfo.quantity,
      flotingProfit: flotingProfit ? flotingProfit : "0.0",
      closePrice: closePrice,
    };
    const res = await closeOrderFx(orderObj);
    if (res.data.success) {
      toast.success(t(res.data.message));
      setRefresh((prev) => !prev);
      setCurrentClosingOrder(null);
      handleClose();
    } else {
      toast.error(t(res.data.message));
      setCurrentClosingOrder(null);
    }
  };
  const closePartialOrder = async () => {
    const orderObj = {
      orderId: selectedRowData.id,
      orderPair: selectedRowData.symbol,
      closeQuantity: volumeToClose,
    };
    const res = await partialOrderClose(orderObj);
    if (res.data.success) {
      toast.success(t(res.data.message));
      setRefresh((prev) => !prev);
      handleClose();
    } else {
      toast.error(t(res.data.message));
    }
  };

  const handleAssetsChangePending = (e) => {
    const value = e.target.value;

    if (value === "") {
      setQuantity("");
      return;
    }
    // Allow user to type floating-point numbers freely
    if (value <= 1000) {
      if (/^(\d+\.?\d{0,2}|\.\d{0,2})$/.test(value)) {
        setQuantity(value);
      }
    }
  };

  const activeBuyOrderHtml = () => {
    const bids = marketDepth
      .filter((item) => item.MDEntryType === "Bid")
      .slice(0, 4); // Display only the first 3 items
    const maxQuantity = Math.max(...bids.map((bid) => bid.MDEntrySize));

    return (
      <tbody>
        {bids.length > 0 ? (
          bids.map((bid, index) => {
            const widthPercent = (bid.MDEntrySize / maxQuantity) * 100;
            const depthQty = parseFloat(bid.MDEntrySize / 100000);
            return (
              <tr key={index} className="tr_prograss_depth">
                <td className="f-left ng-binding green crypt-up">
                  <span
                    className="tr_prograss2"
                    style={{ width: `${widthPercent}%` }}
                  ></span>
                  {bid.MDEntryPx.toFixed(5)}
                </td>
                <td>{depthQty.toLocaleString()}</td>
                <td className="text-right">
                  {(bid.MDEntryPx * depthQty).toFixed(2)}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  // Display only the first 3 sell orders (offers)
  const activeSellOrderHtml = () => {
    const offers = marketDepth
      .filter((item) => item.MDEntryType === "Offer")
      .slice(0, 4)
      .reverse(); // Reverse the array      ; // Display only the first 3 items

    const maxQuantity = Math.max(...offers.map((offer) => offer.MDEntrySize));
    return (
      <tbody>
        {offers.length > 0 ? (
          offers.map((offer, index) => {
            const widthPercent = (offer.MDEntrySize / maxQuantity) * 100;
            const depthQty = parseFloat(offer.MDEntrySize / 100000);

            return (
              <tr key={index} className="tr_prograss_depth">
                <td className="f-left ng-binding magenta crypt-down">
                  <span
                    className="tr_prograss"
                    style={{ width: `${widthPercent}%` }}
                  ></span>
                  {offer.MDEntryPx.toFixed(5)}
                </td>
                <td>{depthQty.toLocaleString()}</td>
                <td className="text-right">
                  {(offer.MDEntryPx * depthQty).toFixed(2)}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  const sideLastHtml = () => {
    var collectHtml = (
      <ul>
        <li>
          <strong className="ng-binding">{symbol}</strong>
          <strong className="transMoney ng-binding ng-scope"></strong>
        </li>
      </ul>
    );
    return <ul>{collectHtml}</ul>;
  };

  const handleSideChange = (side) => {
    setErrors(defaultError);
    setSideAcvive(side);
    if (isStopLoss) {
      const currPrice = side === "buy" ? selectedBid : selectedAsk;
      const priceBN = new BigNumber(currPrice);
      const changePrice = priceBN.div(100);
      let addedPrice;

      if (side === "buy") {
        addedPrice = priceBN.plus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.minus(twoPercent);
      } else {
        addedPrice = priceBN.minus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.plus(twoPercent);
      }
      const formattedTakeProfit = formatTakeProfit(price, addedPrice);
      setStopPrice(isNaN(addedPrice.toNumber()) ? 0 : formattedTakeProfit);
    }
    if (isTakeProfit) {
      const currPrice = side === "buy" ? selectedBid : selectedAsk;
      const priceBN = new BigNumber(currPrice);
      const changePrice = priceBN.div(100);
      let addedPrice;

      if (side === "buy") {
        addedPrice = priceBN.plus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.plus(twoPercent);
      } else {
        addedPrice = priceBN.minus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.minus(twoPercent);
      }
      const formattedTakeProfit = formatTakeProfit(price, addedPrice);
      setTakeProfit(isNaN(addedPrice.toNumber()) ? 0 : formattedTakeProfit);
    }
    setPrice(side === "buy" ? selectedAsk : selectedBid);
  };

  const formatNumberIso = (number) => {
    return number?.toNumber().toFixed(2);
  };

  const handleRightClick = (event, data) => {
    event.preventDefault();
    setCurrentRightClickData(data);
    setMenuPosition({ x: event.clientX - 150, y: event.clientY - 120 });
    setShowMenu(true);
  };
  const handleAction = (action) => {
    if (action === "close") {
      handleCloseOrder(
        currentRightClickData.item,
        currentRightClickData.flotingProfit,
        currentRightClickData.currentPricea
      );
    }

    if (action === "modify") {
      const rowData = currentRightClickData.item;
      setSideAcvive(rowData.order_side.toLowerCase());
      setPrice(rowData.price);
      setStopPrice(rowData.stop_price);
      setTakeProfit(rowData.take_profit);
      setSelectedRowData(rowData);
      setShow(true);
      handleSocketMessages(localSocket, rowData.symbol);
    }

    setShowMenu(false);
  };

  useEffect(() => {
    let totalFloatingProfit = new BigNumber(0);

    closedOrders.forEach((item) => {
      const currentPricea = new BigNumber(item.closed_price);
      const orderPrice = new BigNumber(item.price);
      let priceChange;
      if (item.order_side === "BUY") {
        priceChange = currentPricea.minus(orderPrice);
      } else if (item.order_side === "SELL") {
        priceChange = orderPrice.minus(currentPricea);
      }
      let floatingProfit;
      if (item.symbol.startsWith("XAU") || item.symbol.startsWith("XAG")) {
        const quantity = parseFloat(item.quantity);
        floatingProfit = priceChange.multipliedBy(quantity * 100); // Gold uses 100 ounces per lot
      } else if (item.symbol_type === "forex") {
        const quantity = parseFloat(item.quantity) * parseFloat(100000);
        if (item.symbol.endsWith("JPY")) {
          // Special handling for JPY pairs
          floatingProfit = priceChange
            .multipliedBy(quantity)
            .dividedBy(currentPricea);
        } else {
          floatingProfit = priceChange?.multipliedBy(quantity);
        }
      }

      const commission = new BigNumber(item.commission || 0); // Assuming commission is part of the item object
      const profitAfterCommission = floatingProfit.minus(commission);

      totalFloatingProfit = totalFloatingProfit.plus(profitAfterCommission);
    });

    setFloatingProfitsClose(totalFloatingProfit);
  }, [closedOrders]);

  const openOrderTable = () => {
    if (openOrders.length > 0) {
      return openOrders.map((item, index) => {
        const key = `${item.symbol}`;
        const matchingCoin = priceMap[key]; // Lookup prices in O(1) time

        const best_bid = matchingCoin ? matchingCoin.best_bid : item.best_bid;
        const best_ask = matchingCoin ? matchingCoin.best_ask : item.best_ask;
        const currentPricea = matchingCoin
          ? item.order_side === "BUY"
            ? new BigNumber(best_bid)
            : new BigNumber(best_ask)
          : item.order_side === "BUY"
          ? new BigNumber(item.buyPrice)
          : new BigNumber(item.sellPrice);
        const orderPrice = new BigNumber(item.price);
        let priceChange;
        if (item.order_side === "BUY") {
          priceChange = currentPricea.minus(orderPrice);
        } else if (item.order_side === "SELL") {
          priceChange = orderPrice.minus(currentPricea);
        }
        let floatingProfit;

        if (item.symbol.startsWith("XAU") || item.symbol.startsWith("XAG")) {
          const quantity = parseFloat(item.quantity);
          floatingProfit = priceChange.multipliedBy(quantity * 100); // Gold uses 100 ounces per lot
        } else if (item.symbol_type === "forex") {
          const quantity = parseFloat(item.quantity) * parseFloat(100000);
          if (item.symbol.endsWith("JPY")) {
            // Special handling for JPY pairs
            floatingProfit = priceChange
              .multipliedBy(quantity)
              .dividedBy(currentPricea);
          } else {
            floatingProfit = priceChange?.multipliedBy(quantity);
          }
        } else if (item.symbol_type === "equity") {
          const quantity = parseFloat(item.quantity);
          floatingProfit = priceChange?.multipliedBy(quantity);
        }

        const grossProfitLoss = floatingProfit;

        // Calculate P&L with commission deducted
        const commission = item.commission
          ? new BigNumber(item.commission)
          : new BigNumber(0);
        const netProfitLoss = grossProfitLoss.minus(commission);
        // const swap = calculateSwap(item);

        return (
          <tr
            key={index}
            style={{ cursor: "pointer" }}
            onDoubleClick={() => handleDoubleClick(item)}
            onContextMenu={(e) =>
              handleRightClick(e, {
                item,
                flotingProfit: formatNumberIso(floatingProfit),
                currentPricea: formatNumberIso(currentPricea),
              })
            }
            disabled={positionClosing}
          >
            <td>{item.symbol}</td>
            <td>
              <span
                className="ng-binding ng-scope"
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {t(item.order_side)}
              </span>
            </td>
            <td>{item.quantity}</td>
            <td>{item.price}</td>
            <td>{item.order_side === "BUY" ? best_bid : best_ask}</td>
            <td>{item.take_profit ? item.take_profit : "-"}</td>
            <td>{item.stop_price ? item.stop_price : "-"}</td>
            <td>
              {item.commission ? "$" + formatDecimal(item.commission, 5) : "-"}
            </td>
            <td>{item.swap ? "$" + item.swap : "-"}</td>
            <td>{moment(item.created_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td
              style={
                floatingProfit < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              $
              {isNaN(floatingProfit) ? "0.0" : formatDecimal(floatingProfit, 2)}
            </td>
            <td
              style={
                netProfitLoss < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              ${isNaN(netProfitLoss) ? "0.0" : formatDecimal(netProfitLoss, 5)}
            </td>
            <td>
              <span
                className="mr-3"
                style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                onClick={() => handleDoubleClick(item)}
              >
                <i class="bi bi-pencil-square"></i>
              </span>
              {positionClosing && currentOrderClosing.id === item.id ? (
                <span
                  className="loading-spin"
                  style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                >
                  <div class="loader" style={{ display: "inline-block" }}></div>
                </span>
              ) : (
                <span
                  style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                  disabled={positionClosing}
                  onClick={() =>
                    handleCloseOrder(
                      item,
                      formatNumberIso(floatingProfit),
                      formatNumberIso(currentPricea)
                    )
                  }
                >
                  <i className="bi bi-x-circle"></i>
                </span>
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={13}>
            {t("You have no open orders")}
          </td>
        </tr>
      );
    }
  };

  const allOrdersTable = () => {
    if (allOrdersData.length > 0) {
      return allOrdersData.map((item, index) => {
        const key = `${item.symbol}`;
        const matchingCoin = priceMap[key]; // Lookup prices in O(1) time

        const best_bid = matchingCoin ? matchingCoin.best_bid : item.best_bid;
        const best_ask = matchingCoin ? matchingCoin.best_ask : item.best_ask;

        let allStatus;
        let colour;
        if (item?.status === "close") {
          allStatus = "Filled";
          colour = "#01d59b";
        } else if (item?.status === "pending") {
          allStatus = "Working";
          colour = "#2962ff";
        } else if (item?.status === "cancled") {
          allStatus = "Cancled";
          colour = "#ff9800";
        }
        return (
          <tr key={index}>
            <td>{item.symbol}</td>
            <td>
              <span
                className="ng-binding ng-scope "
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {t(item.order_side)}
              </span>
            </td>
            <td>{item.order_type}</td>
            <td>{item.quantity}</td>
            <td> {item.price}</td>
            <td>{item.order_side === "BUY" ? best_ask : best_bid} </td>
            <td>{item.take_profit ? item.take_profit : "-"}</td>
            <td>{item.stop_price ? item.stop_price : "-"}</td>
            <td style={{ color: colour }}>{item.status ? allStatus : "-"}</td>
            <td>{item.id ? item.id : "-"}</td>
            {item?.status === "pending" && (
              <td>
                <span
                  style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                  onClick={() => handleCancleOrder(item)}
                >
                  <i className="bi bi-x-circle"></i>
                </span>
              </td>
            )}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={10}>
            {t("There is no trading data yet")}
          </td>
        </tr>
      );
    }
  };

  const pendingOrderTable = () => {
    if (pendingOrders.length > 0) {
      return pendingOrders.map((item, index) => {
        const key = `${item.symbol}`;
        const matchingCoin = priceMap[key]; // Lookup prices in O(1) time

        const best_bid = matchingCoin ? matchingCoin.best_bid : item.best_bid;
        const best_ask = matchingCoin ? matchingCoin.best_ask : item.best_ask;
        return (
          <tr key={index}>
            <td>{item.symbol}</td>
            <td>
              <span
                className="ng-binding ng-scope "
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {t(item.order_side)}
              </span>
            </td>
            <td>{item.quantity}</td>
            <td>
              {" "}
              {item.order_side === "BUY" ? best_ask : best_bid}{" "}
              <i className="bi bi-arrow-right"></i> {item.price}
            </td>
            <td>{item.take_profit ? item.take_profit : "-"}</td>
            <td>{item.stop_price ? item.stop_price : "-"}</td>
            <td>
              <span
                style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                onClick={() => handleCancleOrder(item)}
              >
                <i className="bi bi-x-circle"></i>
              </span>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={7}>
            {t("You have no pending orders")}
          </td>
        </tr>
      );
    }
  };

  const closeOrderTable = () => {
    if (closedOrders.length > 0) {
      return closedOrders.map((item, index) => {
        const currentPricea = new BigNumber(item.closed_price);
        const orderPrice = new BigNumber(item.price);
        let priceChange;
        if (item.order_side === "BUY") {
          priceChange = currentPricea.minus(orderPrice);
        } else if (item.order_side === "SELL") {
          priceChange = orderPrice.minus(currentPricea);
        }
        let floatingProfit;
        if (item.symbol.startsWith("XAU") || item.symbol.startsWith("XAG")) {
          const quantity = parseFloat(item.quantity);
          floatingProfit = priceChange.multipliedBy(quantity * 100); // Gold uses 100 ounces per lot
        } else if (item.symbol_type === "forex") {
          const quantity = parseFloat(item.quantity) * parseFloat(100000);
          if (item.symbol.endsWith("JPY")) {
            // Special handling for JPY pairs
            floatingProfit = priceChange
              .multipliedBy(quantity)
              .dividedBy(currentPricea);
          } else {
            floatingProfit = priceChange?.multipliedBy(quantity);
          }
        } else if (item.symbol_type === "equity") {
          const quantity = parseFloat(item.quantity);
          floatingProfit = priceChange?.multipliedBy(quantity);
        }
        const grossProfitLoss = floatingProfit;

        // Calculate P&L with commission deducted
        const commission = item.commission
          ? new BigNumber(item.commission)
          : new BigNumber(0);
        const netProfitLoss = grossProfitLoss.minus(commission);
        return (
          <tr key={index}>
            <td>{item.symbol}</td>
            <td>
              <span
                className="ng-binding ng-scope "
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {t(item.order_side)}
              </span>
            </td>
            <td>{item.quantity}</td>
            <td>{moment(item.created_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td>{moment(item.updated_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td>
              {item.commission ? "$" + formatDecimal(item.commission, 5) : "-"}
            </td>
            <td>{item.swap ? "$" + item.swap : "-"}</td>
            <td>
              {item.price} <i className="bi bi-arrow-right"></i>{" "}
              {item.closed_price}
            </td>
            <td
              style={
                floatingProfit < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              $
              {isNaN(floatingProfit) ? "0.0" : formatDecimal(floatingProfit, 2)}
            </td>
            <td
              style={
                netProfitLoss < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              ${isNaN(netProfitLoss) ? "0.0" : formatDecimal(netProfitLoss, 5)}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={9}>
            {t("You have no close orders")}
          </td>
        </tr>
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errorMessages = { ...errors };

    // Allow empty value to clear the field
    if (value === "") {
      if (name === "price") {
        setPrice(value);
        errorMessages.price = "This field is required";
      }
      if (name === "stopPrice") {
        setStopPrice(value);
        errorMessages.stopPrice = "This field is required";
      }
      if (name === "takeProfit") {
        setTakeProfit(value);
        errorMessages.takeProfit = ""; // No error message when empty
      }
      setErrors(errorMessages);
      return;
    }

    const regex = /^\d{1,5}(\.\d{0,5})?$/;
    if (!regex.test(value)) {
      return;
    }

    if (name === "price") {
      setPrice(value);
      if (value <= 0) {
        errorMessages.price = "Price should be greater than zero";
      } else {
        errorMessages.price = "";
      }
    }

    if (name === "stopPrice") {
      setStopPrice(value);
      if (sideAcvive === "buy") {
        if (value !== "" && Number(value) >= Number(selectedBid)) {
          errorMessages.stopPrice =
            "Set stop loss lower than the current level";
        } else {
          errorMessages.stopPrice = "";
        }
      } else if (sideAcvive === "sell") {
        if (value !== "" && Number(value) <= Number(selectedAsk)) {
          errorMessages.stopPrice =
            "Set stop loss higher than the current level";
        } else {
          errorMessages.stopPrice = "";
        }
      }
    }

    if (name === "takeProfit") {
      setTakeProfit(value);
      if (value !== "") {
        // Only validate if there's a value
        if (sideAcvive === "buy") {
          if (Number(value) <= Number(selectedAsk)) {
            errorMessages.takeProfit =
              "Set take profit higher than the current level";
          } else {
            errorMessages.takeProfit = "";
          }
        } else if (sideAcvive === "sell") {
          if (Number(value) >= Number(selectedBid)) {
            errorMessages.takeProfit =
              "Set take profit lower than the current level";
          } else {
            errorMessages.takeProfit = "";
          }
        }
      } else {
        errorMessages.takeProfit = ""; // No error when empty
      }
    }

    setErrors(errorMessages);
  };

  const validateOrder = () => {
    let isValid = true;

    if (isStopLoss) {
      if (sideAcvive === "buy") {
        if (stopPrice >= selectedBid) {
          toast.error(t("Set stop loss lower than the current level"));
          isValid = false;
        }
      } else if (sideAcvive === "sell") {
        if (Number(stopPrice) <= Number(selectedAsk)) {
          toast.error(t("Set stop loss higher than the current level"));
          isValid = false;
        }
      }
    }

    if (isTakeProfit) {
      if (sideAcvive === "buy") {
        if (takeProfit <= selectedAsk) {
          toast.error(t("Set take profit higher than the current level"));
          isValid = false;
        }
      } else if (sideAcvive === "sell") {
        if (Number(takeProfit) >= Number(selectedBid)) {
          toast.error(t("Set take profit lower than the current level"));
          isValid = false;
        }
      }
    }

    return isValid;
  };

  const modifyOrder = async () => {
    let isValid = true;
    if (stopPrice) {
      if (sideAcvive === "buy") {
        if (stopPrice >= selectedBid) {
          toast.error(t("Set stop loss lower than the current level"));
          isValid = false;
        }
      } else if (sideAcvive === "sell") {
        if (Number(stopPrice) <= Number(selectedAsk)) {
          toast.error(t("Set stop loss higher than the current level"));
          isValid = false;
        }
      }
    }

    if (takeProfit) {
      if (sideAcvive === "buy") {
        if (takeProfit <= selectedAsk) {
          toast.error(t("Set take profit higher than the current level"));
          isValid = false;
        }
      } else if (sideAcvive === "sell") {
        if (Number(takeProfit) >= Number(selectedBid)) {
          toast.error(t("Set take profit lower than the current level"));
          isValid = false;
        }
      }
    }

    if (!isValid) return;

    const orderObj = {
      orderId: selectedRowData.id,
      orderSide: sideAcvive === "buy" ? "BUY" : "SELL",
      stopPrice: stopPrice ? stopPrice : null,
      takeProfit: takeProfit ? takeProfit : null,
    };
    // console.log(orderObj, "order obje");
    // return;
    const resp = await modifyOrderClick(orderObj);
    if (resp.data.success) {
      toast.success(t(resp.data.message));
      handleClose();
      setStopPrice("");
      setErrors(defaultError);
    } else {
      toast.error(t(resp.data.message));
    }
  };

  const createPendingOrder = async (e) => {
    e.preventDefault();
    if (!validateOrder()) {
      return;
    }

    let errorMessages = { ...errors };

    if (!price) {
      errorMessages.price = "This field is required";
      setErrors(errorMessages);
      return false;
    }

    if (price <= 0) {
      errorMessages.price = "Price should be greater than zero";
      setErrors(errorMessages);
      return false;
    }

    const orderObj = {
      orderSide: sideAcvive === "buy" ? "BUY" : "SELL",
      orderPrice: price,
      orderQuantity: quantity,
      orderPair: symbol,
      orderType: isStopLoss === false ? "limit" : "stop-loss",
      status: "pending",
      stopPrice: isStopLoss ? stopPrice : "",
      takeProfit: isTakeProfit ? takeProfit : "",
      symbolSide: "forex",
    };
    const resp = await createOrderOneClick(orderObj);
    if (resp.data.success) {
      toast.success(t(resp.data.message));
      // setPrice("");
      setQuantity(0.01);

      // setIsStopLoss(false);
      setIsTakeProfit(false);
    } else {
      toast.error(t(resp.data.message));
    }
  };

  const handleCancleOrder = async (order) => {
    const orderObj = {
      orderId: order.id,
    };
    const resp = await cancleOrderFx(orderObj);
    if (resp.data.success) {
      toast.success(t(resp.data.message));
    } else {
      toast.error(t(resp.data.message));
    }
  };

  const handleOrderTypeCHange = (e) => {
    const value = e;
    setOrderMethod(value);
    if (value === "stop-loss") {
      // setIsStopLoss(true);
      const currPrice = sideAcvive === "buy" ? selectedBid : selectedAsk;
      const priceBN = new BigNumber(currPrice);
      const changePrice = priceBN.div(100);
      let addedPrice;

      if (sideAcvive === "buy") {
        addedPrice = priceBN.plus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.minus(twoPercent);
      } else {
        addedPrice = priceBN.minus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.plus(twoPercent);
      }
      const formattedTakeProfit = formatTakeProfit(price, addedPrice);
      setStopPrice(isNaN(addedPrice.toNumber()) ? 0 : formattedTakeProfit);
    } else if (value === "take-profit") {
      const currPrice = sideAcvive === "buy" ? selectedAsk : selectedBid;
      const priceBN = new BigNumber(currPrice);
      const changePrice = priceBN.div(100);
      let addedPrice;

      if (sideAcvive === "buy") {
        addedPrice = priceBN.plus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.plus(twoPercent);
      } else {
        addedPrice = priceBN.minus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.minus(twoPercent);
      }

      setTakeProfit(isNaN(addedPrice.toNumber()) ? 0 : addedPrice.toNumber());
    }
  };

  var titleData = !currentPrice
    ? "0" +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      symbol +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      `${ProductName} Exchange`
    : currentPrice > 1
    ? currentPrice.toLocaleString() +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      symbol +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      `${ProductName} Exchange`
    : currentPrice +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      symbol +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      `${ProductName} Exchange`;

  const meta = {
    title: titleData.toString(),
    description: `${ProductName} `,
    canonical: `https://${ProductName}.io/`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Bitcoin Exchange, Crypto Buying Selling, Crypto Platform, Cryptocurrencies App ",
      },
    },
  };

  const resetLayout = (type) => {
    if (type === "forex") {
      setLayout(layouts);
      setRemovedItems(notAllowedWidgets);
      saveToLocalStorage("layouts", layouts);
      saveToLocalStorage("frl", []);
    }
  };

  const changeChartTheme = (theme) => {
    if (theme === "defaultTheme" || theme === "darkTheme") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  const removeItem = (itemId) => {
    for (const key in layout) {
      if (layout.hasOwnProperty(key)) {
        layout[key] = layout[key].filter((item) => item.i !== itemId);
      }
    }

    setLayout(layout);
    saveToLocalStorage("layouts", layout);

    setRemovedItems((prevRemovedItems) => [...prevRemovedItems, itemId]);
    setTimeout(() => {
      handleLayoutChange(undefined, layout);
    }, 300);
  };

  const addItem = (newItem) => {
    const updatedLayout = { ...layout };

    let chartData = null;

    for (const key in layouts) {
      if (layouts.hasOwnProperty(key)) {
        const chartItem = layouts[key].find((item) => item.i === newItem);

        if (chartItem) {
          chartData = chartItem;
          break;
        }
      }
    }

    const chartIndex = updatedLayout.lg.findIndex((item) => item.i === newItem);

    if (chartIndex !== -1) {
      updatedLayout.lg[chartIndex] = chartData;
    } else {
      updatedLayout.lg.push(chartData);
    }

    setRemovedItems((prevRemovedItems) =>
      prevRemovedItems.filter((itemId) => itemId !== newItem)
    );

    saveToLocalStorage(
      "frl",
      removedItems.filter((itemId) => itemId !== newItem)
    );

    setLayout(updatedLayout);

    saveToLocalStorage("layouts", updatedLayout);
    setTimeout(() => {
      handleLayoutChange(undefined, updatedLayout);
    }, 300);
  };

  function checkValueExists(value) {
    return removedItems.includes(value);
  }

  const getLayoutSettings = (section, status, page) => {
    const notAllowed = notAllowedWidgets.includes(section);

    if (notAllowed) return;
    if (!status) {
      removeItem(section);
    } else {
      addItem(section);
    }
  };

  useEffect(() => {
    for (const item of notAllowedWidgets) {
      removeItem(item);
    }
  }, [notAllowedWidgets]);

  const notificationTable = () => {
    if (notificationData.length > 0) {
      return notificationData.map((item, index) => {
        return (
          <tr key={index}>
            <td>{moment(item.created_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td>{item.title}</td>
            <td>{item.message}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={3}>
            {t("You don't have any notifications!")}
          </td>
        </tr>
      );
    }
  };

  const cancleOrderTable = () => {
    if (cancleOrders.length > 0) {
      return cancleOrders.map((item, index) => {
        if (item.symbol_type === "forex") {
          return (
            <tr key={index}>
              <td>{item.symbol}</td>
              <td>
                <span
                  className="ng-binding ng-scope "
                  style={
                    item.order_side === "BUY"
                      ? { color: "rgb(0, 188, 139)" }
                      : { color: "#eb6a61" }
                  }
                >
                  {t(item.order_side)}
                </span>
              </td>
              <td>{item.quantity}</td>
              <td> {item.price}</td>
              <td>{item.take_profit ? item.take_profit : "-"}</td>
              <td>{item.stop_price ? item.stop_price : "-"}</td>
            </tr>
          );
        }
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={7}>
            {t("You have no pending orders")}
          </td>
        </tr>
      );
    }
  };

  const handleBuyPercentMargin = () => {};

  const handleChangeDifOrder = (segment) => {
    if (segment === "take-profit") {
      const newIsTakeProfit = !isTakeProfit;
      setIsTakeProfit(newIsTakeProfit);

      if (!newIsTakeProfit) {
        setTakeProfit(""); // Reset when both are false
        return;
      }

      const currPrice = sideAcvive === "buy" ? selectedBid : selectedAsk;
      const priceBN = new BigNumber(currPrice);
      const changePrice = priceBN.div(100);
      let addedPrice;

      if (sideAcvive === "buy") {
        addedPrice = priceBN.plus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.plus(twoPercent);
      } else {
        addedPrice = priceBN.minus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.minus(twoPercent);
      }
      const formattedTakeProfit = formatTakeProfit(price, addedPrice);
      setTakeProfit(isNaN(addedPrice.toNumber()) ? 0 : formattedTakeProfit);
    } else if (segment === "stop-loss") {
      const newIsStopLoss = !isStopLoss;
      setIsStopLoss(newIsStopLoss);

      if (!newIsStopLoss) {
        setStopPrice(""); // Reset when both are false
        return;
      }

      const currPrice = sideAcvive === "buy" ? selectedBid : selectedAsk;
      const priceBN = new BigNumber(currPrice);
      const changePrice = priceBN.div(100);
      let addedPrice;

      if (sideAcvive === "buy") {
        addedPrice = priceBN.plus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.minus(twoPercent);
      } else {
        addedPrice = priceBN.minus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.plus(twoPercent);
      }
      const formattedTakeProfit = formatTakeProfit(price, addedPrice);
      setStopPrice(isNaN(addedPrice.toNumber()) ? 0 : formattedTakeProfit);
    }
  };

  const AuthActionMargin = () => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      let marketStatus;
      if (orderMethod === "market") {
        marketStatus = "MKT";
      } else if (orderMethod === "limit") {
        marketStatus = `@ ${price} LMT`;
      } else {
        marketStatus = `@ ${price} STP`;
      }
      return (
        <button
          className={
            sideAcvive === "buy"
              ? "btn w100  my_color_buy"
              : "btn w100  my_color_sell"
          }
          type="submit"
          disabled={isLoading || !price || !quantity}
        >
          {sideAcvive === "buy" ? (
            <div>
              <span>{t("Buy")}</span>
              <br />
              <small>
                {(orderMethod === "market" || orderMethod === "stop-loss") &&
                  quantity}{" "}
                {symbol} {marketStatus}
              </small>
            </div>
          ) : (
            <div>
              <span>{t("Sell")}</span>
              <br />
              <small>
                {(orderMethod === "market" || orderMethod === "stop-loss") &&
                  quantity}{" "}
                {symbol} {marketStatus}
              </small>
            </div>
          )}
        </button>
      );
    }
    if (token == null) {
      return (
        <div className="orderforms-inputs">
          <div className="border2 p-2 tc">
            <Link to="/register-for-bitcoin-exchange">Sign Up</Link> or{" "}
            <Link to="/login">{t("Login")}</Link>
          </div>
        </div>
      );
    }
  };
  const handleVolumeChange = (e) => {
    let value = e.target.value;
    if (value === "") {
      setVolumeToClose("");
      return;
    }
    // Allow user to type floating-point numbers freely
    if (value <= 1000) {
      if (/^(\d+\.?\d{0,2}|\.\d{0,2})$/.test(value)) {
        setVolumeToClose(value);
      }
    }
  };

  return (
    <div className="forex_fix1">
      <DocumentMeta {...meta}>
        <ExchangeNavBar
          resetLayout={resetLayout}
          changeChartTheme={changeChartTheme}
          getLayoutSettings={getLayoutSettings}
          layoutChanged={removedItems}
          refresh={refresh} // Pass refresh trigger as a prop
          notAllowedWidgets={notAllowedWidgets}
        />

        {/* {layout && ( */}
        {/* <ResponsiveGridLayout
            className="layout dashbord_manbox exchange-main-outer fs13"
            layouts={layout}
            breakpoints={{
              xl: 2500,
              lg: 1200,
              md: 996,
              sm: 768,
              xs: 480,
              xxs: 0,
            }}
            cols={{ xl: 12, lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            rowHeight={30}
            margin={[1, 1]}
            onLayoutChange={handleLayoutChange}
            resizeHandles={["se", "sw", "ne", "nw"]}
            draggableHandle=".drag-handle"
            draggableCancel=".drag-cancel"
          > */}
        <div className="layout dashbord_manbox exchange-main-outer fs13 ">
          <div className="bg2 kline-para kline-para-basic d-md-flex align-items-center ">
            {/* top bar */}
            <div className="box-kline-para-basic-left ">
              <span
                className="productSymbol has-info ng-scope"
                style={{ fontSize: "11px" }}
              >
                <strong className="ng-binding">{symbol}</strong>
              </span>
            </div>
            <div className="box-kline-para-basic-right forex_list ">
              {topBarHtml()}
            </div>
          </div>
          <div className="dashbord_in grid-three dashbord_in_top fs13 ">
            <div className="forex_left_box padding-right  forex_left_table order-md-1">
              {!checkValueExists("pairslist") && (
                <div className=" ne_box p-3 pt-md-0 pb-md-0">
                  <h6 className="drag-handle text-left">{t("Assets")}</h6>
                  <div className="search-box search-box-new">
                    <input
                      type="text"
                      id="search-int"
                      placeholder={t("Search")}
                      className="ng-pristine ng-untouched ng-valid ng-empty"
                      onChange={onSearch}
                      value={searchedValue}
                      autoComplete="off"
                    />
                    {searchedValue !== "" ? (
                      <i
                        onClick={EmptySearch}
                        className="las la-times-circle"
                      ></i>
                    ) : (
                      <i className="las la-search"></i>
                    )}
                  </div>
                  <div className="instrument-filter mb-2 d-flex flex-wrap gap-2">
                    {["All", "Forex", "Metals", "Energies"].map((filter) => (
                      <button
                        key={filter}
                        className={`${
                          selectedFilter === filter ? "active" : ""
                        }`}
                        onClick={() => setSelectedFilter(filter)}
                      >
                        {filter}
                      </button>
                    ))}
                  </div>
                  <table className="table mb-0 currency_table1 ">
                    <thead>
                      <tr>
                        <th
                          className="f-left r-market-pair ng-binding"
                          ng-click="sortByKey('baseAsset')"
                        >
                          {t("Symbol")}
                        </th>
                        <th className="f-left r-market-price ng-binding">
                          {t("Spread")}
                        </th>
                        <th className="r-market-rate ng-binding ng-scope text-right">
                          {t("Ask/Bid")}
                        </th>
                      </tr>
                    </thead>
                  </table>

                  <div className="table-overflow-forex">
                    <DndProvider backend={HTML5Backend}>
                      <table className="table table-hover currency_table">
                        {assetsListHtml()}
                      </table>
                    </DndProvider>
                  </div>
                </div>
              )}{" "}
            </div>
            <div className="order-md-3">
              {!checkValueExists("buy") && (
                <div key="buy " className="">
                  <div
                    className="close-resize drag-cancel"
                    onClick={() => removeItem("buy")}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ne_box  p-3 pt-md-0 pb-md-0  br10 bg2 ">
                    <div className="d-flex mb-3">
                      <h6 class="h6_head drag-handle mb-0">
                        {t("Order & Trading")}{" "}
                      </h6>
                    </div>
                    <ul class="exchange_tab2  drag-handle br10  d-flex mb-3 position-relative">
                      <span className="bs_center">
                        {formatDecimal(selectedAsk - selectedBid, 5)}
                      </span>
                      <li
                        onClick={() => handleSideChange("sell")}
                        className={`drag-cancel ${
                          sideAcvive === "sell" ? "my_color_sell " : ""
                        }`}
                      >
                        {t("Sell")}
                        <br />
                        {!selectedAsk ? "0" : formatDecimal(selectedAsk, 5)}
                      </li>
                      <li
                        onClick={() => handleSideChange("buy")}
                        className={`drag-cancel ${
                          sideAcvive === "buy" ? "my_color_buy " : ""
                        }`}
                      >
                        {t("Buy")}
                        <br />
                        {!selectedBid ? "0" : formatDecimal(selectedBid, 5)}
                      </li>
                    </ul>
                    <div class="position_tab position_tab_center d-flex mb-3">
                      <a
                        href="#!"
                        class={orderMethod === "market" ? "active" : ""}
                        onClick={() => handleOrderTypeCHange("market")}
                      >
                        {" "}
                        Market
                      </a>
                      <a
                        href="#!"
                        class={orderMethod === "limit" ? "active" : ""}
                        onClick={() => handleOrderTypeCHange("limit")}
                      >
                        Limit
                      </a>
                      <a
                        href="#!"
                        class={orderMethod === "stop-loss" ? "active" : ""}
                        onClick={() => handleOrderTypeCHange("stop-loss")}
                      >
                        Stop
                      </a>
                    </div>
                    <div className="buy-btc-outer buy-sell-outer border1">
                      <form
                        name="buyForm"
                        autoComplete="off"
                        onSubmit={
                          orderMethod === "market"
                            ? handleBuyOrder
                            : createPendingOrder
                        }
                        className="ng-pristine ng-invalid ng-invalid-required"
                      >
                        <div className="orderforms-inputs">
                          <div className="field f-cb mb-2">
                            {orderMethod !== "market" && (
                              <label className="ng-binding d-flex">
                                {t("Price")}{" "}
                              </label>
                            )}
                            <div className="iptwrap leftBig">
                              {orderMethod !== "market" && (
                                <>
                                  <input
                                    type={
                                      orderMethod === "limit"
                                        ? "number"
                                        : "text"
                                    }
                                    className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                                    value={
                                      orderMethod === "market"
                                        ? t("MARKET")
                                        : price
                                    }
                                    name="price"
                                    onChange={handleChange}
                                    disabled={
                                      orderMethod === "market" ? true : false
                                    }
                                  />

                                  <span className="text-danger mb-2">
                                    {t(errors.price)}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="field f-cb mb-2">
                            <label className="ng-binding d-flex">
                              {t("Quantity")}:
                            </label>
                            <div className="iptwrap">
                              <input
                                type="text"
                                step="any"
                                value={quantity}
                                name="quantity"
                                onChange={handleAssetsChangePending}
                              />
                            </div>
                          </div>
                          <div className="row ml-0 mr-0 pt-3  mb-3 align-items-end">
                            <div className="col-5 pl-0">
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input check_box"
                                  type="checkbox"
                                  checked={isTakeProfit}
                                  id="flexCheckChecked"
                                />
                                <span
                                  class="checkmark"
                                  onClick={() =>
                                    handleChangeDifOrder("take-profit")
                                  }
                                ></span>
                                <label
                                  className="form-check-label pl-2"
                                  htmlFor="flexCheckChecked"
                                  onClick={() =>
                                    handleChangeDifOrder("take-profit")
                                  }
                                >
                                  Take Profit
                                </label>
                              </div>
                              <input
                                type="text"
                                value={takeProfit}
                                placeholder="0"
                                name="takeProfit"
                                disabled={!isTakeProfit}
                                onChange={handleChange}
                              />
                            </div>
                            <div
                              className="col-2 d-flex"
                              style={{ justifyContent: "center" }}
                            >
                              <label>Price</label>
                            </div>
                            <div className="col-5 pr-0">
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input check_box"
                                  type="checkbox"
                                  checked={isStopLoss}
                                />
                                <span
                                  class="checkmark"
                                  onClick={() =>
                                    handleChangeDifOrder("stop-loss")
                                  }
                                ></span>
                                <label
                                  className="form-check-label pl-2"
                                  for="flexCheckChecked"
                                  onClick={() =>
                                    handleChangeDifOrder("stop-loss")
                                  }
                                >
                                  Stop Loss
                                </label>
                              </div>
                              <input
                                className=""
                                type="text"
                                placeholder="0"
                                value={stopPrice}
                                name="stopPrice"
                                onChange={handleChange}
                                disabled={!isStopLoss}
                              />
                            </div>
                          </div>
                          <div className="field percent f-cb">
                            <div className="d-flex field4 mb-2">
                              <span
                                className={`col ${
                                  percentAmount === 25 ? "my_color_buy" : ""
                                }`}
                                onClick={() => handleBuyPercentMargin(25)}
                                disabled
                              >
                                25%
                              </span>
                              <span
                                className={`col ${
                                  percentAmount === 50 ? "my_color_buy" : ""
                                }`}
                                onClick={() => handleBuyPercentMargin(50)}
                                disabled
                              >
                                50%
                              </span>
                              <span
                                className={`col ${
                                  percentAmount === 75 ? "my_color_buy" : ""
                                }`}
                                onClick={() => handleBuyPercentMargin(75)}
                                disabled
                              >
                                75%
                              </span>
                              <span
                                className={`col ${
                                  percentAmount === 100 ? "my_color_buy" : ""
                                }`}
                                onClick={() => handleBuyPercentMargin(100)}
                                disabled
                              >
                                100%
                              </span>
                            </div>
                          </div>
                          <div className="field f-cb mb-2">
                            <label className="ng-binding">{t("Total")}: </label>
                            <div className="iptwrap">
                              <input
                                type="text"
                                step="any"
                                id="total_buy"
                                name="buy_order_total"
                                disabled
                                className="ng-pristine ng-untouched ng-valid ng-empty"
                                value={monetary ? monetary.toString() : ""}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p style={{ fontSize: 10 }} className="ng-binding">
                              {t("Trade Fee")} :{" "}
                              {feesType === "Flat" && <span>$</span>}
                              {sideAcvive === "buy"
                                ? buyCommission
                                : sellCommission}
                              {feesType === "Percentage" && <span>%</span>}
                            </p>
                            <p style={{ fontSize: 10 }} className="ng-binding">
                              {t("Net Amount")} :{" "}
                              {feeAmyt ? formatDecimal(feeAmyt, 5) : 0}
                            </p>
                          </div>
                          <div className="orderforms-inputs">
                            {AuthActionMargin()}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-6 order-md-2">
              {!checkValueExists("chart") && (
                <div key="chart" className="drag-handle ">
                  <div className="ne_box br10  bg2 h387">
                    <div className="binance-chart-container">
                      <div
                        className="close-resize drag-cancel"
                        onClick={() => removeItem("chart")}
                      >
                        <i className="bi bi-x-lg"></i>
                      </div>

                      <div className="h-100 ">
                        <TradingViewForexWidgit theme={theme} symbol={symbol} />
                        <ul class="chart_buy_sell  d-flex  position-absolute align-items-center">
                          <li
                            class="drag-cancel my_color_sell"
                            onClick={() => handleChartOrderMargin("SELL")}
                          >
                            {selectedAsk}
                          </li>

                          <input
                            className="bg2 drag-cancel"
                            type="number"
                            value={quantity}
                            name="quantity"
                            onChange={handleAssetsChangePending}
                          />

                          <li
                            class="drag-cancel my_color_buy"
                            onClick={() => handleChartOrderMargin("BUY")}
                          >
                            {selectedBid}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="fs13 d-md-flex">
            <div className="w350">
              {!checkValueExists("marketdepth") && (
                <div className=" forex_t_height ">
                  <div className="ne_box  p-3 br10  bg2">
                    <h6 className="drag-handle text-left">
                      {t("Market Depth")}
                    </h6>
                    <div
                      className="close-resize drag-cancel"
                      onClick={() => removeItem("marketdepth")}
                    >
                      <i className="bi bi-x-lg"></i>
                    </div>
                    <div className=" bs_border t-height1">
                      <table className="table mb-0 market_depth_table">
                        <thead>
                          <tr>
                            <th>{t("Price")}</th>
                            <th>{t("Qty.")}</th>
                            <th className="text-right">{t("Total")}</th>
                          </tr>
                        </thead>
                        {activeSellOrderHtml()}
                      </table>
                    </div>
                    <h6 className="text-center t2t m-0">{sideLastHtml()}</h6>
                    <div className="bs_border t-height1 ">
                      <table className="table mb-0 market_depth_table">
                        <thead>
                          <tr>
                            <th>{t("Price")}</th>
                            <th>{t("Qty.")}</th>
                            <th className="text-right">{t("Total")}</th>
                          </tr>
                        </thead>
                        {activeBuyOrderHtml()}
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="w100">
              {!checkValueExists("orderdetails") && (
                <div className="ne_box pl-3 pr-3 pb-3 pt-2 br10 h100 bg2 w100">
                  <h6 className=" drag-handle text-left">{t("Orders")}</h6>
                  <div
                    className="close-resize drag-cancel"
                    onClick={() => removeItem("orderdetails")}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>

                  <div className="position_tab d-md-flex mb-3">
                    <div className=" d-flex mb-2 mb-md-0">
                      <a
                        className={
                          activeOrderTab === "positions"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        onClick={() => setActiveOrderTab("positions")}
                      >
                        {t("Positions")} {ordersCount > 0 ? ordersCount : ""}
                      </a>
                      <a
                        className={
                          activeOrderTab === "all" ||
                          activeOrderTab === "working" ||
                          activeOrderTab === "filled" ||
                          activeOrderTab === "cancelled"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        onClick={() => setActiveOrderTab("all")}
                      >
                        {t("Orders")}
                      </a>
                      <a
                        className={
                          activeOrderTab === "account_summary"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        onClick={() => setActiveOrderTab("account_summary")}
                      >
                        {t("Account Summary")}
                      </a>
                      <a
                        className={
                          activeOrderTab === "notification_summary"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        onClick={() =>
                          setActiveOrderTab("notification_summary")
                        }
                      >
                        {t("Notification Summary")}
                      </a>
                    </div>
                    <div className="total_profit ml-auto">
                      {activeOrderTab === "positions" ? (
                        <h6 className="mb-0 mb-md-2">
                          {t("Open")} {t("P & L")}:{" "}
                          <span
                            style={
                              floatingProfits < 0
                                ? { color: "#f23345" }
                                : { color: "#00bc8b" }
                            }
                          >
                            ${formatDecimal(floatingProfits, 5)}
                          </span>
                        </h6>
                      ) : (
                        <h6 className="mb-0 mb-md-2">
                          {t("Close")} {t("P & L")}:{" "}
                          <span
                            style={
                              floatingProfitsClose < 0
                                ? { color: "#f23345" }
                                : { color: "#00bc8b" }
                            }
                          >
                            ${formatDecimal(floatingProfitsClose, 5)}
                          </span>
                        </h6>
                      )}
                    </div>
                  </div>
                  {(activeOrderTab === "all" ||
                    activeOrderTab === "working" ||
                    activeOrderTab === "filled" ||
                    activeOrderTab === "cancelled") && (
                    <div className="position_tab_in d-flex mb-2 mb-md-0">
                      <a
                        href="#!"
                        onClick={() => setActiveOrderTab("all")}
                        className={activeOrderTab === "all" ? "active" : ""}
                      >
                        {t("All")}{" "}
                        {allOrdersData.length > 0 ? allOrdersData.length : ""}
                      </a>
                      <a
                        href="#!"
                        onClick={() => setActiveOrderTab("working")}
                        className={activeOrderTab === "working" ? "active" : ""}
                      >
                        {t("Working")}{" "}
                        {pendingOrders.length > 0 ? pendingOrders.length : ""}
                      </a>
                      <a
                        href="#!"
                        onClick={() => setActiveOrderTab("filled")}
                        className={activeOrderTab === "filled" ? "active" : ""}
                      >
                        {t("Filled")}{" "}
                        {closedOrders.length > 0 ? closedOrders.length : ""}
                      </a>
                      <a
                        href="#!"
                        onClick={() => setActiveOrderTab("cancelled")}
                        className={
                          activeOrderTab === "cancelled" ? "active" : ""
                        }
                      >
                        {t("Cancelled")}{" "}
                        {cancleOrders.length > 0 ? cancleOrders.length : ""}
                      </a>
                    </div>
                  )}

                  <div className="forex_table_scrool mt-2">
                    {showMenu && (
                      <ContextMenu
                        x={menuPosition.x}
                        y={menuPosition.y}
                        onAction={handleAction}
                        menuRef={menuRef}
                      />
                    )}
                    {activeOrderTab === "positions" ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{t("Symbol")}</th>
                            <th>{t("Direction")}</th>
                            <th>{t("Quantity, lots")}</th>
                            <th>{t("Entry point")}</th>
                            <th>{t("Price change")}</th>
                            <th>{t("Take Profit")}</th>
                            <th>{t("Stop Loss")}</th>
                            <th>{t("Commission")}</th>
                            <th>{t("Swap")}</th>
                            <th>{t("Open Time")}</th>
                            <th>{t("Gross Profit")}</th>
                            <th>{t("Profit")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>{openOrderTable()} </tbody>
                      </table>
                    ) : activeOrderTab === "all" ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{t("Symbol")}</th>
                            <th>{t("Direction")}</th>
                            <th>{t("Type")}</th>
                            <th>{t("Quantity, lots")}</th>
                            <th>{t("Entry point")}</th>
                            <th>{t("Price change")}</th>
                            <th>{t("Take Profit")}</th>
                            <th>{t("Stop Loss")}</th>
                            <th>{t("Status")}</th>
                            <th>{t("OrderID")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>{allOrdersTable()}</tbody>
                      </table>
                    ) : activeOrderTab === "working" ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{t("Symbol")}</th>
                            <th>{t("Direction")}</th>
                            <th>{t("Quantity, lots")}</th>
                            <th>{t("Entry Price")}</th>
                            <th>{t("Take Profit")}</th>
                            <th>{t("Stop Loss")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>{pendingOrderTable()}</tbody>
                      </table>
                    ) : activeOrderTab === "filled" ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{t("Symbol")}</th>
                            <th>{t("Direction")}</th>
                            <th>{t("Quantity, lots")}</th>
                            <th>{t("Open Time")}</th>
                            <th>{t("Close Time")}</th>
                            <th>{t("Commission")}</th>
                            <th>{t("Swap")}</th>
                            <th>{t("Price change")}</th>
                            <th>{t("Gross Profit")}</th>
                            <th>{t("P & L")}</th>
                          </tr>
                        </thead>
                        <tbody>{closeOrderTable()}</tbody>
                      </table>
                    ) : activeOrderTab === "cancelled" ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{t("Symbol")}</th>
                            <th>{t("Direction")}</th>
                            <th>{t("Quantity, lots")}</th>
                            <th>{t("Entry Price")}</th>
                            <th>{t("Take Profit")}</th>
                            <th>{t("Stop Loss")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>{cancleOrderTable()}</tbody>
                      </table>
                    ) : activeOrderTab === "account_summary" ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{t("Title")}</th>
                            <th>{t("Balance")}</th>
                            <th>{t("Open PL")}</th>
                            <th>{t("Equity")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {" "}
                          <tr>
                            <td>{t("User Balance")}</td>
                            <td>
                              {userBalance
                                ? Number.isInteger(userBalance)
                                  ? userBalance
                                  : parseFloat(userBalance)
                                : 0}
                            </td>
                            <td>{formatDecimal(floatingProfits, 5)}</td>
                            <td>
                              {" "}
                              {new BigNumber(userBalance ? userBalance : 0)
                                .plus(floatingProfits)
                                .toFixed(5)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{t("Time")}</th>
                            <th>{t("Title")}</th>
                            <th>{t("Message")}</th>
                          </tr>
                        </thead>
                        <tbody>{notificationTable()}</tbody>
                      </table>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* </ResponsiveGridLayout> */}
        {/* )} */}
        {/* suraj Added modify code*/}
        {show && (
          <Modal show={show} onHide={handleClose} className="light-model">
            <Modal.Header>
              <Modal.Title className="h6">Order Management</Modal.Title>
              <span style={{ cursor: "pointer" }}>
                <i onClick={handleClose} className="bi bi-x-circle"></i>
              </span>
            </Modal.Header>
            <Modal.Body>
              {/* Custom Tabs */}
              <div className="d-flex pending_in_tab mb-3 mt-3 bg1 br10 p-1 drag-cancel">
                <button
                  className={`tab-btn ${
                    activeTab === "modify" ? "active" : ""
                  }`}
                  onClick={() => setActiveTabModify("modify")}
                >
                  Modify Order
                </button>
                <button
                  className={`tab-btn ${
                    activeTab === "partialClose" ? "active" : ""
                  }`}
                  onClick={() => setActiveTabModify("partialClose")}
                >
                  Partial Close
                </button>
              </div>

              {/* Modify Order Tab */}
              {activeTab === "modify" && (
                <div>
                  <div className="order-info d-flex justify-content-between">
                    Order ID: {orderId}{" "}
                    <span>Entry Price: ${displayPrice}</span>
                  </div>
                  <div className="d-flex pending_in_tab mb-3 mt-3 bg1 br10 p-1 drag-cancel">
                    <button
                      className={sideAcvive === "sell" ? "active " : ""}
                      disabled
                    >
                      Sell
                      <span
                        style={
                          sideAcvive === "sell"
                            ? { color: "white" }
                            : { color: "#eb6a61" }
                        }
                      >
                        {selectedAsk}
                      </span>
                    </button>
                    <button
                      className={sideAcvive === "buy" ? "active" : ""}
                      disabled
                    >
                      Buy
                      <span
                        style={
                          sideAcvive === "buy"
                            ? { color: "white" }
                            : { color: "rgb(0, 188, 139)" }
                        }
                      >
                        {selectedBid}
                      </span>
                    </button>
                  </div>
                  <div className="mb-3">
                    <label className="d-flex align-items-center justify-content-between">
                      {t("Stop Loss")}
                      {stopLosspl ? <span>Amount: {stopLosspl} $</span> : ""}
                    </label>
                    <div>
                      <input
                        type="number"
                        className="form-control"
                        value={stopPrice}
                        name="stopPrice"
                        onChange={handleChange}
                      />
                      <div className="text-danger">{t(errors.stopPrice)}</div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="d-flex align-items-center justify-content-between">
                      {t("Take Profit")}
                      {takeProfitAmount ? (
                        <span>Amount: {takeProfitAmount}$</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <div>
                      <input
                        type="number"
                        className="form-control"
                        value={takeProfit}
                        name="takeProfit"
                        onChange={handleChange}
                      />
                      <div className="text-danger">{t(errors.takeProfit)}</div>
                    </div>
                  </div>
                  <div className="place_order_fix">
                    <button className="btn w100 mt-2 p-2" onClick={modifyOrder}>
                      Modify order
                    </button>
                  </div>
                </div>
              )}

              {/* Partial Close Tab */}
              {activeTab === "partialClose" && (
                <div>
                  <div className="mb-3">
                    <label className="d-flex align-items-center">
                      {t("Volume to close")}
                    </label>
                    <div>
                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          name="volumeToClose"
                          className={`form-control ${
                            isButtonDisabled ? "active" : ""
                          }`}
                          step={"any"}
                          value={volumeToClose}
                          onChange={handleVolumeChange}
                        />
                      </div>
                    </div>
                    <small className="d-flex mt-2">
                      0.01 - {selectedRowData.quantity}
                    </small>
                  </div>
                  <button
                    className="btn btn-warning w-100"
                    style={{ width: "100%" }}
                    disabled={isButtonDisabled}
                    onClick={closePartialOrder}
                  >
                    Close Order
                  </button>
                  {relizedProfit !== 0 && relizedProfit !== undefined && (
                    <div
                      className="text-center"
                      style={{
                        color: relizedProfit > 0 ? "green" : "red",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: "20px",
                      }}
                    >
                      <p>Estimate Profit:</p>
                      <p>{relizedProfit}</p>
                    </div>
                  )}
                </div>
              )}
            </Modal.Body>
          </Modal>
        )}

        <Version />
      </DocumentMeta>
    </div>
  );
};

export default ForexExchange;
