import React from "react";

const Version = () => {
  return (
    <div className="copy-right bg2 p-3 text-center">
      © 2025 My Meta FX. All Rights Reserved <br />{" "}
      <small>Version 1.1.5</small>
    </div>
  );
};

export default Version;
